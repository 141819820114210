import { DF_HUMAN_WITHOUT_TIME } from 'helpers/dateFormats';
import moment from 'moment';
import { getCloudRegionFullText } from './helper';

export const adaptDBServersResponse = (response) => {
  const _res = response?.response ? response?.response : [];
  return _res.length
    ? _res?.map((_compute) => {
        const ipAddresses = _compute?.ipAddressInfo?.ipAddresses;
        const cloudData = _compute?.cloudLocation?.split('/') || [];
        const cloud = cloudData?.[0];
        const cloudRegion = cloudData?.[1];
        const cloudRegionFullText = getCloudRegionFullText(cloud, cloudRegion);

        return {
          id: _compute?._id,
          serverName: _compute?.name,
          status: _compute?.status,
          owner: _compute?.owner,
          computeType: _compute?.metadata?.computeType,
          privateIp:
            Object.keys(ipAddresses)?.find((k) => {
              return ipAddresses[k]?.['tag'] === 'private_ip';
            }) || '-',
          ipAddresses,
          cloud: cloud,
          cloudRegion: cloudRegion,
          cloudRegionFullName: cloudRegionFullText,
          engineType: _compute?.engineType,
          cloudAccountId: _compute?.cloudAccountId,
          cloudResourceId: _compute?.cloudResourceId,
          dateCreated:
            moment(_compute?.dateCreated).format(DF_HUMAN_WITHOUT_TIME) || '-',
          dbserverSystemId: _compute?.dbserverSystemId,
          deploymentId: _compute?.deploymentId,
          subscriptionId: _compute?.subscriptionId,
          osInfo: _compute?.osInfo,
        };
      })
    : [];
};

export const adaptDBServerOSDetailsResponse = (response) => {
  return (
    response?.reduce((acc, curr) => {
      acc[curr.id] = curr.OSInfo;
      return acc;
    }, {}) ||
    {} ||
    {}
  );
};
