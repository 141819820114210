import { EMPTY_STRING } from 'constants/appConstants';
import { DF_HUMAN } from 'helpers/dateFormats';
import moment from 'moment';

export const adaptAlertsResponse = (response) => {
  return response?.response?.length
    ? response?.response?.map((alert) => {
        return {
          id: alert._id,
          alertName: alert?.data?.labels?.alertname || EMPTY_STRING,
          engine: alert?.data?.labels?.engine || EMPTY_STRING,
          title: alert?.annotations?.summary || EMPTY_STRING,
          job: alert?.data?.labels?.job,
          appName:
            alert?.data?.labels?.app_name ||
            alert?.data?.labels?.app ||
            EMPTY_STRING,
          instance:
            alert?.data?.labels?.instance ||
            alert?.data?.labels?.service_instance_name ||
            alert?.data?.labels?.instance_name ||
            EMPTY_STRING,
          serviceId: alert?.data?.labels?.service_id || EMPTY_STRING,
          compute: alert?.data?.labels?.computeResourceId || EMPTY_STRING,
          am: alert?.data?.labels?.am_name || EMPTY_STRING,
          amId: alert?.data?.labels?.am_id || EMPTY_STRING,
          serviceName: alert?.data?.labels?.service_name || EMPTY_STRING,
          instanceId: alert?.data?.labels?.service_instance_id || EMPTY_STRING,
          description: alert?.annotations?.description || EMPTY_STRING,
          summary: alert?.annotations?.summary || EMPTY_STRING,
          eksNamespace: alert?.data?.labels?.eks_namespace || EMPTY_STRING,
          status: alert?.data?.status || EMPTY_STRING,
          severity: alert?.data?.labels?.severity,
          startsAt: moment(alert?.startsAt)?.format(DF_HUMAN),
          endsAt: moment(alert?.endsAt)?.format(DF_HUMAN),
          resolvedAt: alert?.data?.resolvedAt
            ? moment(alert?.data?.resolvedAt)?.format(DF_HUMAN)
            : EMPTY_STRING,
          resolutionTime: alert?.data?.resolutionTime,
          jiraId: alert?.data?.jiraId,
          labels: alert?.data?.labels,
          alertId: alert?.data?.alertId,
          priority: alert?.data?.priority,
          assignee: alert?.data?.assignee,
          comment: alert?.data?.comment,
        };
      })
    : [];
};

export const alertActionResponseAdapter = (response) => {
  return response?.response ? response?.response : response || {};
};
