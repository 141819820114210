import { GenieStatus, TessellGenieTypeEnum } from 'common/Genie/constant';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { downloadData } from 'common/api/downloadFile';
import { useGetCall, useLazyPostCall } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { useState, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

type VMContainerProps = {
  serviceId?: string;
  computeId?: string;
  tenantId: string;
  tenantDomain: string;
  tenantName: string;
};

export const useVMContainer = ({ computeId, tenantName }: VMContainerProps) => {
  const [genieStatus, setGenieStatus] = useState({
    [TessellGenieTypeEnum.Customer]: { status: GenieStatus.DISABLED },
    [TessellGenieTypeEnum.Internal]: { status: GenieStatus.DISABLED },
  });
  const params = useParams();

  const { databaseServiceId: serviceId, tenantId, tenantDomain } = params;

  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [genieOtpStep, setGenieOtpStep] = useState(1);
  const [otpErrorMsg, setOtpErrorMsg] = useState('');
  const [requestId, setRequestId] = useState('');

  const [state, setState] = useState<any>({});
  const { addNotification } = useContext(NotificationContext);

  const {
    response: genieRequestResponse,
    isLoading: genieRequestResponseLoading,
    postData: fetchGenieRequest,
  } = useGetCall(
    `${URLS.requestGenie}?${
      serviceId ? `service-id=${serviceId}` : `compute-id=${computeId}`
    }&tenant-id=${tenantId}&tenant-domain=${tenantDomain}`,
  );

  const {
    response: genieDisableResponse,
    postData: genieDisablePostData,
    error: genieDisableApiError,
  } = useLazyPostCall(`${URLS.disableGenie}`);

  const {
    response: extendGenieResponse,
    postData: extendGenie,
    error: extendGenieApiError,
  } = useLazyPostCall(`${URLS.extendGenie}`);

  const {
    response: postGenieRequestResponse,
    isLoading: isLoadingGenieRequest,
    postData: genieRequestPostData,
    error: genieRequestError,
  } = useLazyPostCall(URLS?.requestGenie);

  useEffect(() => {
    if (genieRequestError?.message) {
      addNotification({
        severity: 'error',
        message:
          genieRequestError?.message ||
          'Something went wrong. Please try again.',
      });
    }
  }, [genieRequestError]);

  useEffect(() => {
    if (genieRequestResponse?.length === 0) {
      setGenieStatus((prev) => ({
        ...prev,
        [TessellGenieTypeEnum.Internal]: { status: GenieStatus.DISABLED },
      }));
    }
  }, [genieRequestResponse]);

  useEffect(() => {
    if (genieRequestResponse?.length) {
      for (const each of genieRequestResponse) {
        setGenieStatus((prev) => ({
          ...prev,
          [each.genieType]: { ...each },
        }));
      }
    }
  }, [genieRequestResponse]);

  const handleRequest = (state) => {
    setState(state);
    setGenieOtpStep(1);
    setOpenOtpModal(true);
  };

  const onGenieRequest = ({ reason, jiraId, jiraSummary }) => {
    const payload = {
      ...state,
      tenantId: tenantId,
      tenantName: tenantName,
      tenantDomain: tenantDomain,
      jiraId,
      comment: reason,
      ticketSummary: jiraSummary,
    };
    if (serviceId) {
      payload.serviceId = serviceId;
    }
    if (computeId) {
      payload.computes = [computeId];
    }
    genieRequestPostData(JSON.stringify(payload));
  };

  useEffect(() => {
    if (postGenieRequestResponse?.requestId) {
      setRequestId(postGenieRequestResponse?.requestId);
      setGenieOtpStep(2);
      setGenieStatus((prev) => ({
        ...prev,
        [postGenieRequestResponse?.genieType]: { ...postGenieRequestResponse },
      }));
    }
  }, [postGenieRequestResponse]);

  const {
    response: genieEnableResponse,
    isLoading: isLoadingGenieEnable,
    postData: genieEnablePostData,
    error: genieEnableApiError,
  } = useLazyPostCall(`${URLS.enableGenie}`);

  useEffect(() => {
    if (genieEnableApiError?.message) {
      addNotification({
        severity: 'error',
        message:
          genieEnableApiError?.message ||
          'Something went wrong. Please try again.',
      });
    }
  }, [genieEnableApiError]);

  useEffect(() => {
    if (genieEnableResponse?.requestId) {
      setOpenOtpModal(false);
      setGenieStatus((prev) => ({
        ...prev,
        [genieEnableResponse?.genieType]: { ...genieEnableResponse },
      }));
    }
  }, [genieEnableResponse]);

  useEffect(() => {
    if (genieDisableApiError?.message) {
      addNotification({
        severity: 'error',
        message:
          genieDisableApiError?.message ||
          'Something went wrong. Please try again.',
      });
    }
  }, [genieDisableApiError]);

  useEffect(() => {
    if (genieDisableResponse?.requestId) {
      setGenieStatus((prev) => ({
        ...prev,
        [genieDisableResponse?.genieType]: { ...genieDisableResponse },
      }));
    }
  }, [genieDisableResponse]);

  useEffect(() => {
    if (extendGenieApiError?.message) {
      addNotification({
        severity: 'error',
        message:
          extendGenieApiError?.message ||
          'Something went wrong. Please try again.',
      });
    }
  }, [extendGenieApiError]);

  useEffect(() => {
    if (extendGenieResponse?.requestId) {
      setGenieStatus((prev) => ({
        ...prev,
        [extendGenieResponse?.genieType]: { ...extendGenieResponse },
      }));
    }
  }, [extendGenieResponse]);

  const commonInPayload = useMemo(() => {
    return {
      tenantDomain: tenantDomain,
      tenantId: tenantId,
      tenantName: tenantName,
    };
  }, [tenantId, tenantDomain, tenantName]);

  const onGenieOtpSubmit = (otp) => {
    genieEnablePostData(
      JSON.stringify({
        requestId: requestId,
        otp: otp,
        ...commonInPayload,
      }),
    );
  };

  const handleWithdraw = (_requestId) => {
    genieDisablePostData(
      JSON.stringify({
        requestId: _requestId,
        ...commonInPayload,
      }),
    );
  };

  const handleForceRevoke = (_requestId, config) => {
    genieDisablePostData(
      JSON.stringify({
        requestId: _requestId,
        ...commonInPayload,
        ...config,
      }),
    );
  };

  const handleAddComputes = (_requestId, config) => {
    extendGenie(
      JSON.stringify({
        requestId: _requestId,
        ...commonInPayload,
        ...config,
      }),
    );
  };

  const handleExtendDuration = (_requestId, _duration) => {
    extendGenie(
      JSON.stringify({
        requestId: _requestId,
        expiryInMinutes: _duration,
        tenantDomain: tenantDomain,
        tenantId: tenantId,
      }),
    );
  };

  const downloadGenieConfig = (_requestId) => {
    downloadData(
      `${URLS.downloadGenieConfig}tenant-id=${tenantId}&tenant-domain=${tenantDomain}&request-id=${_requestId}`,
      `vm_${_requestId}.zip`,
    );
  };

  const downloadGenieConfigForACompute = (
    _requestId,
    _computeId,
    computeName,
  ) => {
    downloadData(
      `${URLS.downloadGenieConfig}tenant-id=${tenantId}&tenant-domain=${tenantDomain}&request-id=${_requestId}&compute-id=${_computeId}`,
      `vm_${computeName}_${_requestId}.zip`,
    );
  };

  const refreshGenieStatus = (_requestId) => {
    fetchGenieRequest();
  };

  const approveRequest = (_requestId) => {
    setRequestId(_requestId);
    setGenieOtpStep(2);
    setOpenOtpModal(true);
  };

  const declineRequest = (_requestId) => {
    genieDisablePostData(
      JSON.stringify({
        tenantDomain: tenantDomain,
        requestId: _requestId,
        tenantId: tenantId,
        tenantName: tenantName,
        isDeclined: true,
      }),
    );
  };

  return {
    handleRequest,
    refreshGenieStatus,
    genieStatusLoading: genieRequestResponseLoading,
    genieStatus,
    handleWithdraw,
    handleExtendDuration,
    downloadGenieConfig,
    downloadGenieConfigForACompute,
    approveRequest,
    declineRequest,
    openOtpModal,
    setOpenOtpModal,
    genieOtpStep,
    onGenieRequest,
    onGenieOtpSubmit,
    otpErrorMsg,
    isLoadingGenieRequest,
    isLoadingGenieEnable,
    setOtpErrorMsg,
    handleForceRevoke,
    handleAddComputes,
  };
};
