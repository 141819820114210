import { useEffect, useState } from 'react';
import {
  findAndReplaceInNestedObject,
  getObjectFromString,
  getObjectValue,
} from './utils';
import { objectDeepCopy } from 'common/utils';
import { nameLabelTypeMap } from './constants';
import _update from 'lodash/update';
import isEmpty from 'lodash/isEmpty';

export const useUpdateForm = ({
  data,
  updateList = [],
  setPayLoad,
  payload,
}) => {
  const [oldData, setOldData] = useState(
    [...updateList]?.map((key) => [key, getObjectValue(data, key.split('.'))]),
  );

  useEffect(() => {
    if (Array.isArray(updateList)) {
      setOldData(
        [...updateList]?.map((key) => [
          key,
          getObjectValue(data, key.split('.')),
        ]),
      );
    }
  }, [data]);

  // based on key type (object or primitive type, update the data)
  const handleChange = (_val, i) => {
    const split = oldData[i][0]?.split('.');
    const baseKey = split[0];
    const lastKey = split[split?.length - 1];

    if (_val === 'LIVE' && lastKey === 'tenantPhase') {
      const index = oldData.findIndex((item) => {
        return item[0].includes('tenantPocOwnerEmails');
      });
      handleChange([], index);
    }

    let updated: any =
      baseKey !== lastKey
        ? {
            [baseKey]: {
              ...(payload?.newData[baseKey] || objectDeepCopy(data[baseKey])),
              [lastKey]: getObjectValue(
                _update(objectDeepCopy(data), oldData[i][0], () =>
                  nameLabelTypeMap[oldData[i][0]]?.type === 'number'
                    ? isNaN(_val)
                      ? 0
                      : parseInt(_val)
                    : getObjectFromString(_val),
                ),
                split,
                'object',
              ),
            },
          }
        : {
            [baseKey]: getObjectValue(
              _update(objectDeepCopy(data), oldData[i][0], () =>
                nameLabelTypeMap[oldData[i][0]]?.type === 'number'
                  ? isNaN(_val)
                    ? 0
                    : parseInt(_val)
                  : getObjectFromString(_val),
              ),
              split,
              'object',
            ),
          };

    if (split?.length > 2) {
      updated = {
        [baseKey]: findAndReplaceInNestedObject(
          isEmpty(payload?.newData)
            ? objectDeepCopy(data[baseKey])
            : { ...data[baseKey], ...payload?.newData[baseKey] },
          lastKey,
          _val,
        ),
      };
    }

    setPayLoad((prev) => {
      return {
        oldData: {
          ...prev.oldData,
          [baseKey]: getObjectFromString(objectDeepCopy(data?.[baseKey])),
        },
        newData: {
          ...prev.newData,
          ...updated,
        },
      };
    });
    setOldData((prev) => [
      ...prev.slice(0, i),
      [oldData[i][0], _val],
      ...prev.slice(i + 1),
    ]);
  };
  return { oldData, handleChange };
};
