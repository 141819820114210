export const addToArray = (array, newString) => {
  const updated = [];
  array?.forEach((val) => {
    updated?.push(val);
  });
  updated?.push(newString);
  return updated;
};

export const removeFromArray = (array, stringToRemove) => {
  return array?.filter((str) => str !== stringToRemove);
};

export const getTruncatedString = (text, limit) => {
  const textWithoutElipsis = text?.slice(0, limit - 3);
  return textWithoutElipsis.concat('...');
};
