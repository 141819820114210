import { Stack, Typography } from '@mui/material';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { useMemo } from 'react';
import { useVNEndpointMetadata } from './useVNEndpointMetadata';

export const VNEndpointMetadata = (props) => {
  const { vnDetails } = props;
  const rows = useVNEndpointMetadata(vnDetails);
  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'targetServiceType',
        headerName: 'Target Service Type',
        width: 100,
      },
      {
        field: 'privateDnsEnabled',
        headerName: 'Private DNS',
        width: 100,
        renderCell: (params) => (
          <>
            <Typography variant="body2" noWrap>
              {params.row.privateDnsEnabled ? 'Enabled' : 'Disabled'}
            </Typography>
          </>
        ),
      },
      {
        field: 'dnsEntries',
        headerName: 'DNS Entries',
        width: 300,
        renderCell: (params) => (
          <>
            {params?.row?.dnsEntries?.map((entry) => (
              <Typography variant="body2" noWrap>
                {entry}
              </Typography>
            ))}
          </>
        ),
      },
    ],
    [],
  );

  return (
    <Stack sx={{ height: '100vh' }} spacing={2}>
      <DataGrid
        componentName="virtual-net-metadat"
        columns={columns}
        rows={rows}
        styleOverrides={{ smallWidth: true }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        totalCount={rows?.length || 0}
      />
    </Stack>
  );
};
