import moment from 'moment';

let sampleResponse = {
  scheduleInfo: {
    backupStartTime: { hour: 1, minute: 0 },
    dailySchedule: {
      backupsPerDay: 0,
      backupStartTimes: ['string'],
    },
    weeklySchedule: {
      weekDays: ['string'],
    },
    monthlySchedule: {
      commonSchedule: {
        dates: [31],
        lastDayOfMonth: false,
      },
      monthSpecificSchedule: [
        {
          month: 'string',
          dates: [31],
        },
      ],
    },
    yearlySchedule: {
      commonSchedule: {
        dates: [31],
        lastDayOfMonth: false,
        months: ['string'],
      },
      monthSpecificSchedule: [
        {
          month: 'string',
          dates: [31],
        },
      ],
    },
  },
};

export const adaptSLATimingResponse = (utcTimeFromResponse) => {
  const utcTime = moment?.utc(utcTimeFromResponse);
  utcTime.tz('Asia/Kolkata');
  utcTime.format('HH:mm')?.toString();
  return `${utcTime.format('HH:mm')?.toString()}` || '-';
};
export const adaptSLAResponse = (response) => {

  return response || {};
};
