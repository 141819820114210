import { useDataAndCount } from 'common/Hooks/useDataAndCount';
import { BACKUP_BASE_URL, URLS } from 'constants/URL';
import { DBBackupType } from 'constants/URLEnums';
import { useEffect, useMemo, useState } from 'react';
import { getCommaSeperatedStringFromArray } from '../utils';
import { useLazyGetCall } from 'common/api/useApiCall';
import { createParams } from 'helpers/createParams';

type SnapshotProps = {
  availabilityMachineId: string;
  tenantId: string;
  backupIdentifier?: string;
};

export const useSnapshots = (props: SnapshotProps) => {
  const { availabilityMachineId, tenantId, backupIdentifier } = props;
  const [updateMetadataOpen, setUpdateMetadataOpen] = useState(false);
  const [selectedSnapshot, setSelectedSnapshot] = useState(null);
  const [openChildrenModal, setOpenChildrenModal] = useState(false);
  const [
    backupIdentifierFromRowClick,
    setSelectedBackupIdentifierFromRowClick,
  ] = useState('');

  const [filters, setFilters] = useState({
    status: 'All',
  });

  const snapshotParams = useMemo(() => {
    return {
      'db-backup-type': DBBackupType.snapshot,
      'availability-machine-id': availabilityMachineId,
      ...(backupIdentifier?.length !== 0
        ? { 'backup-identifier': backupIdentifier }
        : {}),
      status: filters?.status,
    };
  }, [availabilityMachineId, backupIdentifier, filters?.status]);

  const {
    count: snapshotsCount,
    pageCount: snapshotsPageCount,
    dataResponse: snapshots,
    dataIsLoading: snapshotsIsLoading,
    countIsLoading: snapshotsCountIsLoading,
    page,
    setPage,
    handlePaginationChange,
    fetchDataAndCount: fetchSnapshots,
  } = useDataAndCount({
    url: BACKUP_BASE_URL,
    dataResponseAdapter: (response) => {
      return response?.response || [];
    },
    params: snapshotParams,
  });

  const snapshotProgressParams = useMemo(() => {
    return snapshots?.length > 0
      ? {
          'tenant-domain': snapshots?.[0]?.pgSource,
          'snapshot-ids': getCommaSeperatedStringFromArray(
            snapshots?.map((_snapshot) => _snapshot?._id),
          ),
        }
      : {};
  }, [snapshots]);

  const {
    response: snapshotProgressResponse,
    postData: fetchSnapshotProgress,
    isLoading: snapshotProgressIsLoading,
  } = useLazyGetCall(
    `${URLS.getProgressForSnapshots}${createParams(snapshotProgressParams)}`,
  );

  useEffect(() => {
    if (snapshots?.length) {
      fetchSnapshotProgress();
    }
  }, [snapshots]);

  const handleRowClick = (rowClicked) => {
    setSelectedBackupIdentifierFromRowClick(rowClicked?.row?.backupIdentifier);
    setOpenChildrenModal(true);
  };

  const handleResetFilters = () => {
    setFilters({ status: 'All' });
  };

  const handleFilterChange = (filterKey, val) => {
    setPage(1);
    setFilters((prev) => ({ ...prev, [filterKey]: val }));
  };

  return {
    updateMetadataOpen,
    setUpdateMetadataOpen,
    selectedSnapshot,
    setSelectedSnapshot,
    openChildrenModal,
    setOpenChildrenModal,
    snapshotsCount,
    snapshotsPageCount,
    snapshotsIsLoading,
    snapshotsCountIsLoading,
    page,
    handlePaginationChange,
    snapshots,
    snapshotProgress: snapshotProgressResponse,
    snapshotProgressIsLoading,
    handleRowClick,
    backupIdentifierFromRowClick,
    fetchSnapshots,
    filters,
    handleResetFilters,
    handleFilterChange,
  };
};
