import { useContext, useMemo } from 'react';
import { useSnapshots } from '../useSnapshots';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import UpdateMetadata from 'common/UpdateMetadata/UpdateMetadata';
import { SNAPSHOT_BACKUP_STATUS_LIST } from '../../utils';
import { Stack } from '@mui/material';
import { useSnapshotColumns } from '../snapshotsCommon';

type SnapshotsChildrenTableProps = {
  availabilityMachineId: string;
  tenantId: string;
  backupIdentifier?: string;
};

export const SnapshotsChildrenTable = (props: SnapshotsChildrenTableProps) => {
  const { availabilityMachineId, tenantId, backupIdentifier } = props;

  const {
    snapshots,
    snapshotsCount,
    snapshotProgress,
    snapshotProgressIsLoading,
    updateMetadataOpen,
    setUpdateMetadataOpen,
    selectedSnapshot,
    setSelectedSnapshot,
    snapshotsPageCount,
    snapshotsIsLoading,
    page,
    handlePaginationChange,
    fetchSnapshots,
    filters,
    handleResetFilters,
    handleFilterChange,
  } = useSnapshots({
    availabilityMachineId: availabilityMachineId,
    tenantId: tenantId,
    backupIdentifier: backupIdentifier,
  });

  const { hasPermission } = useContext(UserPermissionsContext);

  const menuItemsProps = useMemo(
    () =>
      [
        hasPermission(PERMISSIONS.UPDATE_TENANT) && {
          label: 'Update Metadata',
          onClick: (row) => {
            setSelectedSnapshot(row);
            setUpdateMetadataOpen(true);
          },
        },
      ].filter((a) => a),
    [],
  );

  const snapshotsFilterProps = useMemo(
    () => [
      {
        name: 'Status',
        column: 'status',
        initialVal: filters?.status || 'All',
        options: SNAPSHOT_BACKUP_STATUS_LIST,
        onChange: (value) => handleFilterChange('status', value),
      },
    ],
    [filters],
  );

  const { columns: snapshotsColumns = [] } = useSnapshotColumns({
    snapshotProgress,
    snapshotProgressIsLoading,
  });

  return (
    <Stack sx={{ height: '80vh' }}>
      <DataGrid
        menuItems={menuItemsProps}
        filters={snapshotsFilterProps}
        columns={snapshotsColumns}
        rows={snapshots}
        getRowId={(row) => row?._id}
        loading={snapshotsIsLoading}
        styleOverrides={{ hideShadow: true }}
        pageCount={snapshotsPageCount}
        pageNo={page}
        totalCount={snapshotsCount}
        handlePaginationChange={handlePaginationChange}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        onResetFilters={handleResetFilters}
      />
      <UpdateMetadata
        open={updateMetadataOpen}
        setOpen={setUpdateMetadataOpen}
        entityType="backups"
        entityName={selectedSnapshot?.name}
        parentEntityId={props?.availabilityMachineId}
        tenantId={props?.tenantId}
        tenantDomain={selectedSnapshot?.pgSource}
        entityId={selectedSnapshot?._id}
        handleSuccess={() => fetchSnapshots()}
      />
    </Stack>
  );
};
