import { useGetCall, useLazyGetCall } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { createParams } from 'helpers/createParams';
import { useEffect, useMemo, useState } from 'react';
import { adaptMyRoleResponse } from './adaptMyRoleResponse';
import { adaptParameterProfileResponse } from './adaptParameterProfileResponse';
import { adaptSLAResponse } from './adaptSLAResponse';

type ServiceSummaryProps = {
  pgSource: string;
  tenantId: string;
  entityId: string;
  parameterProfileId: string;
  availabilityMachineId: string;
};

export const useServiceSummary = (props: ServiceSummaryProps) => {
  const {
    pgSource,
    tenantId,
    entityId,
    parameterProfileId,
    availabilityMachineId,
  } = props;
  const [expanded, setExpanded] = useState(true);
  const [computeDetails, setComputeDetails] = useState({});
  const [slaPopoverType, setSlaPopoverType] = useState(null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    slaType = null,
  ) => {
    setSlaPopoverType(slaType);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setSlaPopoverType(null);
    setAnchorEl(null);
  };
  const isPopoverOpen = Boolean(anchorEl);

  const getMyRoleParams = useMemo(() => {
    return {
      'tenant-domain': pgSource,
      'tenant-id': tenantId,
      'dbservice-id': entityId,
    };
  }, [pgSource, tenantId, entityId]);

  const {
    response: myRoleResponse,
    isLoading: myRoleIsLoading,
    postData: getMyRole,
  } = useLazyGetCall(
    `${URLS.getMyRole}${createParams(getMyRoleParams)}`,
    adaptMyRoleResponse,
  );

  const { response: slaResponse, isLoading: slaIsLoading } = useGetCall(
    `${URLS.getSLATiming}availability-machine-id=${availabilityMachineId}&tenant-domain=${pgSource}`,
    adaptSLAResponse,
  );
  const getParameterProfleParams = useMemo(() => {
    return {
      'tenant-domain': pgSource,
      'tenant-id': tenantId,
    };
  }, [pgSource, tenantId]);

  const {
    response: parameterProfileResponse,
    isLoading: parameterProfileIsLoading,
    postData: getParameterProfile,
  } = useLazyGetCall(
    `${URLS.getParameterProfile}${parameterProfileId}?${createParams(
      getParameterProfleParams,
    )}`,
    adaptParameterProfileResponse,
  );

  useEffect(() => {
    if (parameterProfileId?.length !== 0) {
      getParameterProfile();
      getMyRole();
    }
  }, []);

  return {
    loggedInUserRole: myRoleResponse.loggedInUserRole,
    myRoleIsLoading,
    parameterProfileName: parameterProfileResponse?.name,
    parameters: parameterProfileResponse?.parameters,
    parameterProfileIsLoading,
    expanded,
    setExpanded,
    computeDetails,
    setComputeDetails,
    anchorEl,
    setAnchorEl,
    handleClick,
    handleClose,
    isPopoverOpen,
    slaResponse,
    slaIsLoading,
    slaPopoverType,
  };
};
