import { makeStyles } from '@mui/styles';

export function GetSchedule(r) {
  if (r?.dailyBackups > 0) {
    return {
      type: 'Daily',
      dbAnimation: '2s',
      schText: `Daily snapshots for ${r.dailyBackups} days`,
      backupInfo: 'Daily snapshots',
      backupCount: `${r.dailyBackups}`,
      value: 'dailyBackups',
      numType: 'days',
    };
  }
}

export const numberFormatter = new Intl.NumberFormat();

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const MODAL = {
  sanitizeBackup: 'sanitizeBackup',
  schedule: 'schedule',
  updateSchedule: 'updateSchedule',
  deleteSchedule: 'deleteSchedule',
};

export const SNAPSHOT_BACKUP_STATUS_LIST = [
  {
    id: 'All',
    name: 'All',
  },
  {
    id: 'AVAILABLE',
    name: 'Available',
  },
  {
    id: 'QUEUED',
    name: 'Queued',
  },
  {
    id: 'CREATING',
    name: 'Creating',
  },
  {
    id: 'FAILED',
    name: 'Failed',
  },
  {
    id: 'QUEUED_FOR_REPLICATION',
    name: 'Queued for replication',
  },
  {
    id: 'REPLICATING',
    name: 'Replicating',
  },
  {
    id: 'DELETING',
    name: 'Deleting',
  },
  {
    id: 'DELETED',
    name: 'Deleted',
  },
  {
    id: 'DELETION_FAILED',
    name: 'Deletion Failed',
  },
  {
    id: 'DELETED_FOR_SLA',
    name: 'Deleted for SLA',
  },
  {
    id: 'DELETED_FOR_USER',
    name: 'Deleted for user',
  },
  {
    id: 'PREPARING',
    name: 'Preparing',
  },
  {
    id: 'REPLICATION_FAILED',
    name: 'Replication failed',
  },
  {
    id: 'SCHEDULED',
    name: 'Scheduled',
  },
  {
    id: 'SOFT_DELETED',
    name: 'Soft deleted',
  },
];

export const SnapshotType = {
  automated: 'automated',
  manual: 'manual',
};

export const ContentType = {
  'As-Is': 'As-is Data',
  Sanitized: 'Sanitized Data',
  Backup: 'Backup',
};

export const AutomatedOrManualType = {
  automated: 'Automatic',
  manual: 'Manual',
};

export const AM_STATUS = {
  Active: 'ACTIVE',
  Paused: 'PAUSED',
  Stopped: 'STOPPED',
};

export const AM_STATUS_TO_STRING = {
  ACTIVE: 'Ready',
  PAUSED: 'Stopped',
  STOPPED: 'Deleted',
};

export const useStylesForNonClickableRow = makeStyles({
  nonClickableRow: {
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
});

export const getCommaSeperatedStringFromArray = (arr) => {
  const filteredArr = arr?.filter((_i) => _i);
  return filteredArr?.join(',') || '';
};
