import { styles } from 'assets/scss/style-templates';
import MyDatabasesIcon from 'assets/icons/MyDatabasesIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { TabType } from 'common/tab-view/lib/schema/TabType';
import { TabView } from 'common/tab-view/lib/components/TabView';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import { ServiceOverview } from './ServiceOverview';
import _isEmpty from 'lodash/isEmpty';
import { isMultiAZ } from 'helpers/isMultiAZ';
import DbMonitoring from '../db-monitoring/components/DbMonitoring';
import { AvailabilityMachinesTable } from 'customers/tenants-detailed-view/lib/components/availabilityMachines/AvailabilityMachinesTable';
import AvailabilityMachinesIcon from 'assets/icons/AvailabilityMachinesIcon';
import Databases from '../databases/Databases';
import DbInstances from '../instances/DbInstances';
import { IntegrationsIcon, LogsIcon, MonitoringIcon } from 'assets/icons-v2';
import Cache from 'common/cache/lib/utils/Cache';
import { capitaliseFirstLetterInEachWord } from 'helpers/capitaliseFirstLetterInEachWord';
import DBLogs from '../db-logs/DBLogs';
import ConnectionPool from 'assets/icons-v2/ConnectionPool';
import ConnectionPools from '../pools/ConnectionPools';
import DBIntegrations from '../db-integrations/DBIntergrations';
import VMLogs from '../vm-logs/VMLogs';
import STATUS from '../Status';
import { Schedule } from '@mui/icons-material';
import Schedules from '../schedule/Schedule';
import DBEvents from '../events/DBEvents';
import EventsIcon from 'assets/icons/EventsIcon';

enum ServiceTabType {
  Overview = 'Overview',
  Clones = 'Clones',
  Monitoring = 'Monitoring',
  Databases = 'Databases',
  Instances = 'Instances',
  AvailabilityMachine = 'Availability Machine',
  Logs = 'Logs',
  Pools = 'Pools',
  Integrations = 'Integrations',
  VMLogs = 'VM Logs',
  Events = 'Events',
  Schedules = 'Schedules',
}

// Snake to Camel case
const RouteToServiceTabMap = {
  overview: 'Overview',
  monitoring: 'Monitoring',
  'availability-machine': 'AvailabilityMachine',
  databases: 'Databases',
  instances: 'Instances',
  events: 'Events',
  logs: 'Logs',
  pools: 'Pools',
  integrations: 'Integrations',
  'vm-logs': 'VMLogs',
  schedules: 'Schedules',
};

// Camel case to Snake case
const ServiceTabToRouteMap = {
  Overview: 'overview',
  Monitoring: 'monitoring',
  AvailabilityMachine: 'availability-machine',
  Databases: 'databases',
  Instances: 'instances',
  Logs: 'logs',
  Pools: 'pools',
  Integrations: 'integrations',
  VMLogs: 'vm-logs',
  Events: 'events',
  Schedules: 'schedules',
};

interface ServiceTabViewProps {
  service: DatabaseServiceType;
  fetchDBServiceId?: Function;
}

export const ServiceTabView = (props: ServiceTabViewProps): JSX.Element => {
  const navigate = useNavigate();

  const { service, fetchDBServiceId } = props;
  Cache.set(
    'avMachine',
    JSON.stringify(
      service?.availabilitymachine?.map((availabilityMachine) => ({
        id: availabilityMachine?._id,
        tenantId: availabilityMachine?.tenantId,
        name: availabilityMachine?.name,
        engineType: availabilityMachine?.engine,
        cloud: availabilityMachine?.cloudAvailability?.[0]?.cloud,
        pgSource: availabilityMachine?.pgSource,
        region:
          availabilityMachine?.cloudAvailability?.[0]?.regions?.[0]?.region ||
          '',
        sla:
          availabilityMachine?.rpoSla?.sla ||
          availabilityMachine?.metadata?.backupConfiguration?.backupSla,
        dataIngestionStatus: capitaliseFirstLetterInEachWord(
          availabilityMachine?.dataIngestionStatus,
        ),
        dateCreated:
          availabilityMachine?.dateCreated || availabilityMachine?.date_created,
        daps: availabilityMachine?.daps,
      }))[0] || {},
    ),
  );

  const params = useParams();

  const {
    tabId = 'monitoring',
    databaseServiceId,
    tenantId,
    tenantDomain,
  } = params;
  const onTabClick = (event: any, newValue: string) => {
    navigate(
      `/database-services/details/${tenantId}/${tenantDomain}/${databaseServiceId}/${ServiceTabToRouteMap[newValue]}`,
      {
        replace: true,
      },
    );
  };

  const tabValue = RouteToServiceTabMap[tabId] as ServiceTabType;
  const tabsProps: TabType[] = [
    {
      label: ServiceTabType.Overview,
      tabValue: ServiceTabType.Overview,
      render: () => (
        <ServiceOverview
          service={service}
          canUpdateDb={true}
          poolsRes={{ postgresqlConfig: null }}
          isMultiAZ={isMultiAZ(service)}
          canUpdateTags={true}
          fetchDBServiceId={fetchDBServiceId}
        />
      ),
      icon: <MyDatabasesIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: ServiceTabType.Databases,
      tabValue: ServiceTabType.Databases,
      render: () => {
        return <Databases dbServiceId={service?._id} service={service} />;
      },
      icon: <MyDatabasesIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: ServiceTabType.Instances,
      tabValue: ServiceTabType.Instances,
      render: () => {
        return <DbInstances service={service} />;
      },
      icon: <MyDatabasesIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: ServiceTabType.AvailabilityMachine,
      tabValue: 'AvailabilityMachine',
      render: () => {
        return <AvailabilityMachinesTable service={service} />;
      },
      icon: (
        <AvailabilityMachinesIcon height={16} color={styles.color.darkShade} />
      ),
    },
    {
      label: ServiceTabType.Monitoring,
      tabValue: ServiceTabType.Monitoring,
      render: () => <DbMonitoring database={service} />,
      icon: <MonitoringIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: ServiceTabType.Logs,
      tabValue: ServiceTabType.Logs,
      render: () => <DBLogs database={service} />,
      icon: <LogsIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: ServiceTabType.Pools,
      tabValue: ServiceTabType.Pools,
      render: () => <ConnectionPools service={service} />,
      icon: <ConnectionPool height={16} color={styles.color.darkShade} />,
    },
    // {
    //   label: ServiceTabType.Integrations,
    //   tabValue: ServiceTabType.Integrations,
    //   render: () => <DBIntegrations service={service} />,
    //   icon: <IntegrationsIcon height={16} color={styles.color.darkShade} />,
    // },
    service?.status === STATUS.Ready &&
      service?.instances?.filter((_i) => _i?.status !== 'DELETED')?.length && {
        label: ServiceTabType.VMLogs,
        tabValue: 'VMLogs',
        render: () => <VMLogs service={service} />,
        icon: <LogsIcon height={16} color={styles.color.darkShade} />,
      },
    {
      label: ServiceTabType.Events,
      tabValue: ServiceTabType.Events,
      render: () => <DBEvents service={service} />,
      icon: <EventsIcon />,
    },
    {
      label: ServiceTabType.Schedules,
      tabValue: ServiceTabType.Schedules,
      render: () => <Schedules service={service} />,
      icon: (
        <Schedule
          sx={{
            height: 16,
            color: styles.color.darkShade,
          }}
        />
      ),
    },
  ].filter(Boolean);

  const currentTab =
    tabsProps?.find((tab) => tab?.tabValue === tabValue) || tabsProps?.[0];

  return (
    <>
      {!_isEmpty(service) ? (
        <TabView
          tabs={tabsProps}
          onTabClick={onTabClick}
          currentTab={currentTab}
        />
      ) : null}
    </>
  );
};
