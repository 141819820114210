import { useGetCall } from 'common/api/useApiCall';
import { URLS, headers } from 'constants/URL';
import { useEffect, useMemo, useRef, useState } from 'react';
import { LOGS_PAGE_LIMIT } from './constants';
import { useParams } from 'react-router-dom';

type VMLogsProps = {
  pgSource?: string;
  tenantId?: string;
  service?: any;
  instancesFromService?: any;
};

export const useVMLogs = (props: VMLogsProps) => {
  const { service, instancesFromService } = props;
  const params = useParams();

  const {
    databaseServiceId: serviceId,
    tenantId,
    tenantDomain: pgSource,
  } = params;

  const instances = useMemo(() => {
    return instancesFromService?.map((_instance) => {
      return { _id: _instance?.computeResourceId, name: _instance?.name };
    });
  }, [instancesFromService]);
  const [currentLog, setCurrentLog] = useState(null);
  const [selectedLogs, setSelectedLogs] = useState([]);
  const [logFiles, setLogFiles] = useState([]);
  const [logText, setLogText] = useState({});
  const [isLogsLoading, setIsLogsLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState({});

  const [instance, setInstance] = useState(instances?.[0]?._id || '');

  const logViewerRef = useRef();
  const { isLoading, response: logsData } = useGetCall(
    `${URLS.fetchVMLogFiles}engine-type=${service?.engineType}`,
  );

  useEffect(() => {
    if (logsData?.files?.length) {
      setLogFiles(() =>
        logsData?.files?.map((_f) => _f.replace(/\\/g, '\\\\')),
      );
    }
  }, [logsData]);

  async function streamLogs(fl, offset?: any) {
    const url = `${
      URLS.fetchVMLogs
    }tenant-id=${tenantId}&tenant-domain=${pgSource}&compute-id=${instance}&filePath=${encodeURIComponent(
      fl,
    )}&limit=${LOGS_PAGE_LIMIT}&offset=${
      offset !== undefined ? offset : pageOffset[fl] || 0
    }`;
    setIsLogsLoading(true);

    await fetch(url, {
      headers: headers(),
    })
      .then((res) => res.json())
      .then((res) => {
        setLogText((prev) => ({
          ...prev,
          [fl]: {
            text: res?.fileContent
              ? window.atob(res?.fileContent)
              : 'No logs found.',
          },
        }));
      })
      .catch(() => {});
    setIsLogsLoading(false);
  }

  const handleRefresh = () => {
    for (const each of selectedLogs) {
      streamLogs(each);
    }
  };

  return {
    logViewerRef,
    pageOffset,
    currentLog,
    setPageOffset,
    streamLogs,
    handleRefresh,
    logFiles,
    isLoading,
    instances,
    instance,
    setInstance,
    isLogsLoading,
    setCurrentLog,
    selectedLogs,
    setSelectedLogs,
    logText,
    setLogText,
  };
};
