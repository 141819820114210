import { InfoOutlined } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import { styles } from 'assets/scss/style-templates';

type SnapshotProgressItem = {
  id: string;
  status: string;
  progress: string;
  volume: string;
};

interface SnapshotProgressListProps {
  progressList: SnapshotProgressItem[];
}

const snapshotProgressListStyles = {
  container: { display: 'flex', flexDirection: 'row', gap: 1 },
  listItem: { display: 'flex', gap: 1 },
  infoIcon: { fontSize: '16px', color: styles.color.darkShade },
};

export const SnapshotProgressList = (props: SnapshotProgressListProps) => {
  const { progressList } = props;
  return (
    <Stack sx={snapshotProgressListStyles.container}>
      <Stack>
        {progressList?.length > 0 ? (
          progressList?.map((_progressItem) => (
            <Typography>{_progressItem?.progress || '-'}</Typography>
          ))
        ) : (
          <></>
        )}
      </Stack>
      <Tooltip
        placement="right-start"
        arrow
        title={
          <Stack sx={snapshotProgressListStyles.listItem}>
            {progressList?.map((_progressItem) => (
              <Typography>
                Volume: {_progressItem?.volume || '-'},{' '}
                {_progressItem?.progress || '-'}
              </Typography>
            ))}
          </Stack>
        }
      >
        {progressList?.length > 0 ? (
          <InfoOutlined sx={snapshotProgressListStyles.infoIcon} />
        ) : (
          <></>
        )}
      </Tooltip>
    </Stack>
  );
};
