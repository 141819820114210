import { LoadingButton } from '@mui/lab';
import { Button, Chip, MenuItem, Stack, Typography } from '@mui/material';
import DatePickerToggler from 'common/custom-components/lib/components/DatePickerToggler';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import moment from 'moment';
import { styles } from './styles';
import useSilenceAlertsModel from './useSilenceAlerts';
import { OTP_TYPE } from 'constants/otpType';
import OtpModal from '../OtpModal';
import { ChipListing } from 'common/ChipListing/ChipListing';
import LoadingOverlay from 'common/custom-components/lib/components/LoadingOverlay';
import TessellAutocomplete, {
  changeHomogenousArrayToAutoCompleteOption,
} from 'common/custom-components/lib/components/TessellAutocomplete';

type SilenceAlertsModalProps = {
  open: boolean;
  setOpen: any;
  selectedAlertLabels?: any;
  hideInputs?: boolean;
  bulkSilence?: boolean;
  handleOnSuccess: () => void;
};

const rangeType = {
  '1h': '1 hour',
  '3h': '3 hour',
  '6h': '6 hour',
  '12h': '12 hour',
  '24h': '24 hour',
  Custom: 'Custom',
};

const startMin = moment(new Date());

export const SilenceAlertsModal = (props: SilenceAlertsModalProps) => {
  const {
    open,
    setOpen,
    selectedAlertLabels,
    hideInputs = false,
    bulkSilence = false,
    handleOnSuccess,
  } = props;

  const {
    inputs,
    isLoading,
    openOTP,
    sendOTPIsLoading,
    step,
    disableSilence,
    tenants,
    selectedTenant,
    entitiesMap,
    entitiesLoading,
    handleChange,
    setOpenOTP,
    setStep,
    handleConfirm,
    otpSubmitHandler,
    handleDateToggle,
    updateTimeUsingNativePicker,
    range,
  } = useSilenceAlertsModel({
    selectedAlertLabels,
    handleOnSuccess,
    bulkSilence,
  });

  const onClose = () => {
    setOpen(false);
  };

  const DialogTitle = () => <Typography variant="h5">Silence Alert</Typography>;

  const DialogContent = () => {
    return (
      <>
        <Stack sx={styles.form}>
          {selectedAlertLabels ? (
            <ChipListing data={selectedAlertLabels} />
          ) : (
            ''
          )}
          {!hideInputs ? (
            <Stack width="100%" alignItems="center" alignSelf="center" m={3}>
              <TessellInput
                select
                sx={styles.formInput}
                name="tenant_id"
                label="Tenant Id (Required)"
                value={selectedTenant || ''}
                onChange={handleChange}
                placeholder="All alerts of this tenant will be silenced"
                options={tenants.map((v) => (
                  <MenuItem key={v.name} value={v}>
                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                      <Typography variant="subtitle2">{v.name}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              />
            </Stack>
          ) : (
            <></>
          )}
          {!hideInputs && !bulkSilence && entitiesMap ? (
            <LoadingOverlay isLoading={entitiesLoading}>
              <TessellInput
                select
                sx={styles.formInput}
                name="service_id"
                label="Service Id"
                value={inputs?.service_id || ''}
                onChange={handleChange}
                placeholder="All alerts of this service will be silenced"
                options={
                  entitiesMap?.service?.map((v) => (
                    <MenuItem key={v} value={v}>
                      <Stack
                        spacing={1}
                        direction={'row'}
                        alignItems={'center'}
                      >
                        <Typography variant="subtitle2">{v}</Typography>
                      </Stack>
                    </MenuItem>
                  )) || []
                }
              />
              <TessellInput
                select
                sx={styles.formInput}
                name="service_instance_id"
                label="Service Instance Id"
                value={inputs?.service_instance_id || ''}
                onChange={handleChange}
                placeholder="All alerts of this service instance will be silenced"
                options={
                  entitiesMap?.instance?.map((v) => (
                    <MenuItem key={v} value={v}>
                      <Stack
                        spacing={1}
                        direction={'row'}
                        alignItems={'center'}
                      >
                        <Typography variant="subtitle2">{v}</Typography>
                      </Stack>
                    </MenuItem>
                  )) || []
                }
              />
              <TessellInput
                sx={styles.formInput}
                name="alertname"
                label="Alert Name"
                value={inputs?.alertname || ''}
                onChange={handleChange}
                placeholder="All alerts of this type will be silenced"
              />
              <TessellInput
                select
                sx={styles.formInput}
                name="compute_resource_id"
                label="Compute Resource Id"
                value={inputs?.compute_resource_id || ''}
                onChange={handleChange}
                placeholder="All alerts with this compute resource will be silenced"
                options={
                  entitiesMap?.compute?.map((v) => (
                    <MenuItem key={v} value={v}>
                      <Stack
                        spacing={1}
                        direction={'row'}
                        alignItems={'center'}
                      >
                        <Typography variant="subtitle2">{v}</Typography>
                      </Stack>
                    </MenuItem>
                  )) || []
                }
              />
              <TessellInput
                sx={styles.formInput}
                name="job"
                label="Job Name"
                value={inputs?.job || ''}
                onChange={handleChange}
                placeholder="All alerts with this job name will be silenced"
              />
              <TessellInput
                select
                sx={styles.formInput}
                name="eks_namespace"
                label="EKS Namespace"
                value={inputs?.eks_namespace || ''}
                onChange={handleChange}
                placeholder="All alerts from this EKS Namespace will be silenced"
                options={
                  entitiesMap?.eksNamespace?.map((v) => (
                    <MenuItem key={v} value={v}>
                      <Stack
                        spacing={1}
                        direction={'row'}
                        alignItems={'center'}
                      >
                        <Typography variant="subtitle2">{v}</Typography>
                      </Stack>
                    </MenuItem>
                  )) || []
                }
              />
              <TessellInput
                sx={styles.formInput}
                name="mountpoint"
                label="Mountpoint"
                value={inputs?.mountpoint || ''}
                onChange={handleChange}
                placeholder="All alerts of this mountpoint will be silenced"
              />
            </LoadingOverlay>
          ) : (
            <></>
          )}
          {!hideInputs && selectedTenant && bulkSilence ? (
            <LoadingOverlay isLoading={entitiesLoading}>
              <TessellAutocomplete
                label="Service Ids"
                value={inputs?.serviceIds || []}
                variant="outlined"
                size="large"
                onChange={handleChange}
                fullWidth
                options={changeHomogenousArrayToAutoCompleteOption(
                  entitiesMap?.service || [],
                )}
                name="service_id"
                multiple
              />
            </LoadingOverlay>
          ) : (
            <></>
          )}

          <Stack direction="row" spacing={1} sx={styles.timeSelectionFrom}>
            <Typography>Silence alerts for</Typography>
            <DatePickerToggler
              rangeType={rangeType}
              startTime={inputs?.startsAt}
              endTime={inputs?.endsAt}
              updateTimeUsingNativePicker={updateTimeUsingNativePicker}
              startMin={startMin}
              handleOnChange={handleDateToggle}
              range={range}
              additionalInitialSettings={{ drops: 'up' }}
            />
          </Stack>
        </Stack>
        <OtpModal
          open={openOTP}
          setOpen={setOpenOTP}
          onSubmit={otpSubmitHandler}
          isSubmitting={step === 1 ? sendOTPIsLoading : isLoading}
          otpUser="Ops admin"
          step={step}
          setStep={setStep}
          reasonSubmitBtnText="Submit"
          reasonHeading="Enter reason for silencing alerts (with JIRA link)"
          otpType={OTP_TYPE.SILENCE_ALERTS}
        />
      </>
    );
  };

  const DialogActions = () => {
    return (
      <>
        <Button
          onClick={() => {
            setOpen(false);
            setStep(1);
          }}
          variant="outlined"
          size="small"
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={handleConfirm}
          size="small"
          variant="contained"
          loading={false}
          disabled={disableSilence}
        >
          Silence
        </LoadingButton>
      </>
    );
  };

  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  return (
    <TessellModal
      renderFunctions={renderFunctions}
      maxWidth="md"
      open={open}
      onClose={onClose}
    />
  );
};
