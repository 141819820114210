import '../components/TenantCard.scss';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { capitaliseFirstLetterInEachWord } from 'helpers/capitaliseFirstLetterInEachWord';
import { DEFAULT_UNKNOWN } from 'constants/appConstants';
import { DeploymentOptions } from 'constants/deploymentOptions';
import { LabelStrings } from 'customers/tenants-list-view/lib/LabelStrings';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  Box,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { tenantCardSummaryStyles } from './TenantCardSummaryStyles';
import { useToggleBilling } from './Billing/useToggleBilling';
import OtpModal from 'common/Modals/OtpModal';
import { OTP_TYPE } from 'constants/otpType';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { FEATURES_TO_SHOW } from 'customers/tenants-list-view/lib/components/tenantsAdapter';
import TessellLabelValue from 'common/custom-components/lib/components/TessellLabelValue/TessellLabelValue';
import { TessellLabelValueProps } from 'common/custom-components/lib/components/TessellLabelValue/types';
import { OTPRequestType } from 'constants/URLEnums';

interface TenantCardSummaryProps {
  tenant: TenantType;
  creditDetails: any;
  isCreditDetailsLoading: Boolean;
  creditsPostData: any;
  handleToggleBillingSuccess?: any;
}

export const TenantCardSummary = (props: TenantCardSummaryProps) => {
  const {
    tenant,
    creditDetails,
    isCreditDetailsLoading,
    creditsPostData,
    handleToggleBillingSuccess,
  } = props;
  const {
    _id,
    firstName,
    lastName,
    country,
    company,
    status,
    emailAddress,
    metadata,
    onboardingInfo,
    subscriptions = DEFAULT_UNKNOWN,
    pgSource,
    sev1 = '',
    sev2 = '',
    sev3 = '',
    sev4 = '',
    total,
    features,
    tenantPhase,
    POCOwnersList,
    CustomerContactEmailsList,
  } = tenant;

  const { infraInfo = {} as any } = metadata || {};
  const { deploymentType } = infraInfo as any;
  const { domainUrl = '' } = onboardingInfo || {};
  const isBillingEnabled = metadata?.billingInfo?.skipBillGeneration === false;
  const [expanded, setExpanded] = useState(true);
  const { hasPermission } = useContext(UserPermissionsContext);

  const {
    openOTP,
    setOpenOTP,
    step,
    setStep,
    onSubmit,
    setOtpType,
    flags,
    setToggledFlag,
    sendOTPIsLoading,
    toggleBillingIsLoading,
    getTitleAndReason,
  } = useToggleBilling({ tenant, handleToggleBillingSuccess, metadata });

  const handleChange = () => {
    setExpanded((currState) => !currState);
  };

  useEffect(() => {
    if (_id && pgSource) {
      creditsPostData();
    }
  }, [_id, pgSource]);

  const getSummaryHeader = () => {
    if (expanded) {
      return (
        <Typography sx={tenantCardSummaryStyles.title}>
          Tenant Details
        </Typography>
      );
    }
    return accordionHeaderProps
      ?.filter((data) => !!data)
      ?.map((row) => (
        <Grid item xs={12} md={4} key={_id} spacing={3}>
          <TessellLabelValue
            {...row}
            containerStyles={{ justifyContent: 'start' }}
          />
        </Grid>
      ));
  };

  const featureList =
    features
      ?.filter(
        (feature) =>
          feature?.name?.includes(FEATURES_TO_SHOW[0]) ||
          feature?.name?.includes(FEATURES_TO_SHOW[1]),
      )
      ?.map((featureData) => ({
        label: featureData.displayName,
        value: featureData.status,
      })) || [];

  const accordionHeaderProps: TessellLabelValueProps[] = [
    {
      label: LabelStrings?.company,
      value: company,
    },
    {
      label: LabelStrings?.status,
      value: capitaliseFirstLetterInEachWord(status),
    },
    {
      label: LabelStrings?.deploymentOption,
      value: DeploymentOptions[deploymentType] || '-',
    },
  ];

  const cardSummaryProps = useMemo(
    () => [
      {
        label: LabelStrings?.name,
        value: `${firstName} ${lastName}`,
      },
      ...accordionHeaderProps,
      {
        label: LabelStrings?.geoLocation,
        value: country,
      },
      {
        label: LabelStrings?.pocOwner,
        value: POCOwnersList,
      },
      {
        label: LabelStrings.customerContacts,
        value: CustomerContactEmailsList,
      },
      {
        label: LabelStrings?.contactEmail,
        value: emailAddress,
      },
      {
        label: LabelStrings?.id,
        value: _id,
      },
      {
        label: LabelStrings?.subscriptionsForTenant,
        value: `${
          subscriptions && Array.isArray(subscriptions)
            ? subscriptions.length
            : ''
        }`,
        internalLink:
          subscriptions && Array.isArray(subscriptions)
            ? `${window.location.origin}/tenants/details/${_id}/subscriptions`
            : 'No/Missing',
      },
      {
        label: LabelStrings?.domainUrl,
        value: domainUrl,
        linkName: domainUrl,
      },
      metadata?.billingInfo && {
        label: LabelStrings?.billing,
        value: hasPermission(PERMISSIONS.UPDATE_TENANT) ? (
          <FormControlLabel
            labelPlacement="start"
            sx={{ pr: 1 }}
            control={
              <Switch
                size="small"
                color="primary"
                checked={isBillingEnabled}
                onChange={() => {
                  setOtpType(OTPRequestType.BILLING_OTP);
                  setOpenOTP(true);
                }}
                sx={tenantCardSummaryStyles.billingStatusToggle}
              />
            }
            label={`${isBillingEnabled ? 'Enabled' : 'Disabled'}`}
          />
        ) : isBillingEnabled ? (
          'Enabled'
        ) : (
          'Disabled'
        ),
      },
      isBillingEnabled &&
        !creditDetails?.error && {
          label: LabelStrings?.creditUtilization,
          value: isCreditDetailsLoading ? (
            <CircularProgress size={16} />
          ) : (
            <Typography>
              {`$ ${creditDetails?.creditsUsed} / ${creditDetails?.amount}  `}
            </Typography>
          ),
        },
      total && {
        label: LabelStrings?.totalTickets,
        value: total as any,
        internalLink: `/tenants/details/${_id}/freshdesk-tickets`,
      },
      hasPermission(PERMISSIONS.TICKET_VIEW) &&
        sev1 && {
          label: LabelStrings?.sev1,
          value: `${sev1}`,
          internalLink: `/tenants/details/${_id}/freshdesk-tickets?priority=4`,
        },
      hasPermission(PERMISSIONS.TICKET_VIEW) &&
        sev2 && {
          label: LabelStrings?.sev2,
          value: `${sev2}`,
          internalLink: `/tenants/details/${_id}/freshdesk-tickets?priority=3`,
        },
      hasPermission(PERMISSIONS.TICKET_VIEW) &&
        sev3 && {
          label: LabelStrings?.sev3,
          value: `${sev3}`,
          internalLink: `/tenants/details/${_id}/freshdesk-tickets?priority=2`,
        },
      hasPermission(PERMISSIONS.TICKET_VIEW) &&
        sev4 && {
          label: LabelStrings?.sev4,
          value: `${sev4}`,
          internalLink: `/tenants/details/${_id}/freshdesk-tickets?priority=1`,
        },
      ...flags.map((flag) => ({
        label: flag.displayName,
        value: (
          <FormControlLabel
            labelPlacement="start"
            sx={{ pr: 1 }}
            control={
              <Switch
                size="small"
                color="primary"
                checked={flag.value}
                onChange={() => {
                  setToggledFlag([flag.name, flag.value]);
                  setOtpType(OTPRequestType.TENANT_FLAG_TOGGLE_OTP);
                  setOpenOTP(true);
                }}
                sx={tenantCardSummaryStyles.billingStatusToggle}
              />
            }
            label={`${flag.value ? 'Enabled' : 'Disabled'}`}
          />
        ),
      })),
      ...featureList,
    ],
    [flags],
  );
  return (
    <Box sx={tenantCardSummaryStyles.accordionBox} mt={1}>
      <Accordion
        disableGutters
        elevation={0}
        expanded={expanded}
        onChange={handleChange}
      >
        <AccordionSummary
          sx={tenantCardSummaryStyles.headerBackground}
          expandIcon={<ExpandMore />}
        >
          <Grid container direction="row" p={2} spacing={2}>
            {getSummaryHeader()}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid p={2} container direction="row" spacing={3}>
            {cardSummaryProps
              ?.filter((data) => !!data)
              ?.map((row) => (
                <Grid item xs={12} md={6} xl={4} key={_id}>
                  <TessellLabelValue {...row} containerStyles={{ mr: 1 }} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <OtpModal
        open={openOTP}
        setOpen={setOpenOTP}
        title={getTitleAndReason()?.title}
        subTitle={getTitleAndReason()?.subTitle}
        onSubmit={(val) => {
          onSubmit(val);
        }}
        isSubmitting={step === 1 ? sendOTPIsLoading : toggleBillingIsLoading}
        otpUser="Ops admin"
        step={step}
        setStep={setStep}
        reasonHeading={getTitleAndReason()?.reasonHeading}
        otpType={getTitleAndReason()?.otpType}
      />
    </Box>
  );
};
