import { Typography } from '@mui/material';
import { styles } from 'assets/scss/style-templates';
import { getTruncatedString } from 'common/custom-components/helperFunctions';
import { useState } from 'react';

interface TessellTypographyWithShowMoreProps {
  text: string;
  characterLimit?: number;
  textStyles?: any;
  showMoreStyles?: any;
  showMoreOnClick?: () => void;
}

const defaultShowMoreStyles = {
  color: styles.color.darkShade,
  fontWeight: 600,
  fontSize: '14px',
  cursor: 'pointer',
  display: 'inline-block',
};

const TessellTypographyWithShowMore = (
  props: TessellTypographyWithShowMoreProps,
) => {
  const {
    text,
    characterLimit = 170,
    textStyles,
    showMoreStyles = defaultShowMoreStyles,
    showMoreOnClick,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  return text?.length < characterLimit ? (
    <Typography sx={textStyles}>{text}</Typography>
  ) : (
    <Typography
      sx={{
        ...textStyles,
        wordBreak: 'break-all',
      }}
    >
      {isExpanded ? text : getTruncatedString(text, characterLimit)}{' '}
      <Typography
        sx={showMoreStyles}
        onClick={() =>
          showMoreOnClick ? showMoreOnClick() : setIsExpanded((prev) => !prev)
        }
      >
        {'  '}
        {isExpanded && Boolean(!showMoreOnClick) ? ' Show Less' : 'Show More'}
      </Typography>
    </Typography>
  );
};

export default TessellTypographyWithShowMore;
