import { styles } from 'assets/scss/style-templates';
import { useNavigate, useParams } from 'react-router-dom';
import { TabType } from 'common/tab-view/lib/schema/TabType';
import _isEmpty from 'lodash/isEmpty';
import { NetworkIcon, PeeringConnectionIcon, SubnetsIcon } from 'assets/icons-v2';
import Subnets from '../subnets/Subnets';
import { VPCPeerings } from 'customers/virtual-network/vpc-peering';
import { useMemo } from 'react';
import { VNEndpointMetadata } from '../vn-endpoint-metadata/VNEndpointMetadata';

enum VNTabType {
  Subnets = 'Subnets',
  PeeringConnection = 'Peering Connection',
  EndpointMetadata = 'Endpoint Metadata',
}

const RouteToServiceTabMap = {
  'peering-connection': 'Peering Connection',
  subnets: 'Subnets',
  'endpoint-metadata': 'Endpoint Metadata',
};

const ServiceTabToRouteMap = {
  'Peering Connection': 'peering-connection',
  Subnets: 'subnets',
  'Endpoint Metadata': 'endpoint-metadata',
};

type VNTabViewProps = {
  tenant: any;
  vnDetails: any;
};

export const useVNTabView = ({ tenant, vnDetails }: VNTabViewProps) => {
  const navigate = useNavigate();

  const params = useParams();

  const { tabId = 'peering-connection', vnId, tenantId } = params;

  const onTabClick = (event: any, newValue: string) => {
    navigate(
      `/tenants/details/${tenantId}/virtual-network/${vnId}/${ServiceTabToRouteMap[newValue]}`,
      {
        replace: true,
      },
    );
  };

  const tabValue = RouteToServiceTabMap[tabId] as VNTabType;

  const tabsProps: TabType[] = [
    {
      label: VNTabType.Subnets,
      tabValue: VNTabType.Subnets,
      render: () => (
        <Subnets tenant={tenant} subnets={vnDetails?.subnets || []} />
      ),
      icon: <SubnetsIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: VNTabType.PeeringConnection,
      tabValue: VNTabType.PeeringConnection,
      render: () => {
        return <VPCPeerings tenant={tenant} vnDetails={vnDetails} />;
      },
      icon: (
        <PeeringConnectionIcon height={16} color={styles.color.darkShade} />
      ),
    },
    {
      label: VNTabType.EndpointMetadata,
      tabValue: VNTabType.EndpointMetadata,
      render: () => {
        return <VNEndpointMetadata vnDetails={vnDetails} />;
      },
      icon: <NetworkIcon height={16} color={styles.color.darkShade} />,
    },
  ];

  const currentTab = useMemo(
    () =>
      tabsProps?.find((tab) => tab?.tabValue === tabValue) || tabsProps?.[0],
    [tabsProps, tabValue],
  );
  return { tabsProps, onTabClick, currentTab };
};
