import { useGetCall } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { adaptScheduleResponse } from './adaptScheduleResponse';
import { useParams } from 'react-router-dom';


export const useSchedule = () => {
  const params = useParams();

  const {
    databaseServiceId: dbServiceId,
    tenantDomain: pgSource,
  } = params;
  const { response: schedule, isLoading: scheduleIsLoading } = useGetCall(
    `${URLS.fetchStartStop}${dbServiceId}/start-stop?&tenant-domain=${pgSource}`,
    adaptScheduleResponse,
  );

  return {
    schedule,
    scheduleIsLoading,
  };
};
