import { useMemo, useContext } from 'react';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import UpdateMetadata from 'common/UpdateMetadata/UpdateMetadata';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { useSnapshots } from './useSnapshots';
import { SnapshotChildrenModal } from './SnapshotsChildren/SnapshotChildrenModal';
import {
  SNAPSHOT_BACKUP_STATUS_LIST,
  useStylesForNonClickableRow,
} from '../utils';
import { Stack } from '@mui/material';
import { useSnapshotColumns } from './snapshotsCommon';

interface SnapshotsTableProps {
  availabilityMachineId: string;
  tenantId: string;
}

export default function SnapshotsTable(props: SnapshotsTableProps) {
  const { availabilityMachineId, tenantId } = props;
  const nonClickableRowclasses = useStylesForNonClickableRow();
  const { hasPermission } = useContext(UserPermissionsContext);

  const {
    snapshots,
    snapshotProgress,
    snapshotProgressIsLoading,
    snapshotsCount,
    updateMetadataOpen,
    setUpdateMetadataOpen,
    selectedSnapshot,
    setSelectedSnapshot,
    openChildrenModal,
    setOpenChildrenModal,
    snapshotsPageCount,
    snapshotsIsLoading,
    page,
    handlePaginationChange,
    handleRowClick,
    backupIdentifierFromRowClick,
    fetchSnapshots,
    filters,
    handleResetFilters,
    handleFilterChange,
  } = useSnapshots({
    availabilityMachineId: availabilityMachineId,
    tenantId: tenantId,
  });

  const menuItemsProps = useMemo(
    () =>
      [
        hasPermission(PERMISSIONS.UPDATE_TENANT) && {
          label: 'Update Metadata',
          onClick: (row) => {
            setSelectedSnapshot(row);
            setUpdateMetadataOpen(true);
          },
        },
      ].filter((a) => a),
    [],
  );

  const snapshotsFilterProps = useMemo(
    () => [
      {
        name: 'Status',
        column: 'status',
        initialVal: filters?.status || 'All',
        options: SNAPSHOT_BACKUP_STATUS_LIST,
        onChange: (value) => handleFilterChange('status', value),
      },
    ],
    [filters],
  );

  const { columns: snapshotsColumns = [] } = useSnapshotColumns({
    snapshotProgress,
    snapshotProgressIsLoading,
  });

  return (
    <Stack sx={{ height: '80vh' }}>
      <DataGrid
        menuItems={menuItemsProps}
        filters={snapshotsFilterProps}
        columns={snapshotsColumns}
        rows={snapshots}
        getRowId={(row) => row?._id}
        loading={snapshotsIsLoading}
        onRowClick={(row) => {
          if (row?.row?.childrenCount) {
            handleRowClick(row);
          }
        }}
        getRowClassName={(params) => {
          return params?.row?.childrenCount
            ? ''
            : nonClickableRowclasses.nonClickableRow;
        }}
        styleOverrides={{ hideShadow: true }}
        pageCount={snapshotsPageCount}
        totalCount={snapshotsCount}
        pageNo={page}
        handlePaginationChange={handlePaginationChange}
        onResetFilters={handleResetFilters}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
      />
      <UpdateMetadata
        open={updateMetadataOpen}
        setOpen={setUpdateMetadataOpen}
        entityType="backups"
        entityName={selectedSnapshot?.name}
        parentEntityId={props?.availabilityMachineId}
        tenantId={props?.tenantId}
        tenantDomain={selectedSnapshot?.pgSource}
        entityId={selectedSnapshot?._id}
        handleSuccess={() => fetchSnapshots()}
      />
      <SnapshotChildrenModal
        open={openChildrenModal}
        setOpen={setOpenChildrenModal}
        backupIdentifier={backupIdentifierFromRowClick}
        availabilityMachineId={availabilityMachineId}
        tenantId={tenantId}
      />
    </Stack>
  );
}
