import {
  Box,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

const SLAPopover = (props) => {
  const { popoverType, anchorEl, handleClose, isPopoverOpen, data } =
    props;
  return (
    <Popover
      open={isPopoverOpen}
      anchorEl={anchorEl}
      onClose={() => handleClose()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box display="flex" flexDirection="column" p={2} minHeight={50}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>{popoverType} SLA</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.commonSchedule?.dates &&
                getRows({ commonSchedule: data?.commonSchedule })}
              {data?.monthSpecificSchedule?.length && (
                <>{data?.monthSpecificSchedule?.map((row) => getRows(row))}</>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Popover>
  );
};
const getRows = (data) => {
  if (data?.commonSchedule?.dates) {
    return (
      <>
        <TableRow>
          <TableCell>
            <strong>Common Monthly Dates for all months</strong>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{data?.commonSchedule.dates.join(', ')}</TableCell>
        </TableRow>
        {data?.commonSchedule?.lastDayOfMonth && (
          <TableRow>
            <TableCell>Last Day Of Month</TableCell>
            <TableCell>{data?.commonSchedule?.lastDayOfMonth}</TableCell>
          </TableRow>
        )}
        {data?.commonSchedule?.months && (
          <TableRow>
            <TableCell>Months</TableCell>
            <TableCell>{data?.commonSchedule?.months?.join(' ,')}</TableCell>
          </TableRow>
        )}
      </>
    );
  } else if (data?.month) {
    return (
      <>
        <TableRow></TableRow>
        <TableRow>
          <TableCell>
            <strong>{data.month}</strong>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>{data?.dates?.join(', ')}</TableCell>
        </TableRow>
      </>
    );
  }
};

export default SLAPopover;
