/* eslint-disable no-prototype-builtins */
import { useMemo } from 'react';
import { Box, Link, Stack, Typography } from '@mui/material';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import moment from 'moment';
import { DF_HUMAN } from 'helpers/dateFormats';
import OverflowTooltip from 'common/custom-components/lib/components/OverflowTooltip';
import { GetInstanceStatus } from 'customers/db-services-detailed-view/utils';
import { styles } from 'assets/scss/style-templates';
import { iconNameMap } from 'constants/iconMap';
import { useActivityLog } from './useActivityLog';
import { ITEMS_PER_PAGE } from 'constants/appConstants';
import ActivityErrorDrawer from './ErrorDrawer/ActivityErrorDrawer';
import { ChevronRight, ExpandMore } from '@mui/icons-material';

const ActivityLog = (props) => {
  const {
    activities,
    isLoading,
    pageCount,
    defaultFilter,
    handlePaginationChange,
    page,
    allFilters,
    activitiesCount,
    handleFilterChange,
    openExceptionsDrawer,
    setOpenExceptionsDrawer,
    selectedException,
    setSelectedException,
  } = useActivityLog(props);

  const ParseText = (text) => {
    const temp = text.split("'").map((phrase, i) => {
      if (i % 2 === 1) {
        return (
          <Box
            fontWeight="fontWeightMedium"
            display="inline"
          >{` ${phrase} `}</Box>
        );
      }
      return <Typography display="inline">{` ${phrase} `}</Typography>;
    });
    return (
      <Stack direction="row" display="inline">
        {temp}
      </Stack>
    );
  };

  const dbColumns = useMemo(
    () => [
      {
        field: 'activityId',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'entityName',
        headerName: 'Entity Name',
        maxWidth: 200,
        renderCell: (params) => {
          const { row } = params;
          const { entityName } = row;
          return <OverflowTooltip text={entityName} />;
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        maxWidth: 100,
        renderCell: (params) => {
          const { row } = params;
          const { status } = row;
          return <GetInstanceStatus status={status} />;
        },
      },
      {
        field: 'entityType',
        headerName: 'Entity Type',
        maxWidth: 120,
        renderCell: (params) => {
          const { row } = params;
          const { entityType } = row;
          return <OverflowTooltip text={entityType} />;
        },
      },

      {
        field: 'action',
        headerName: 'Action',
        maxWidth: 160,

        renderCell: (params) => {
          const { row } = params;
          const { actionType } = row;
          return <OverflowTooltip text={actionType} />;
        },
      },
      {
        field: 'description',
        // width: 700,
        minWidth: 400,
        headerName: 'Description',
        renderCell: (params) => {
          const { row } = params;
          const { description, activityId, status, exception } = row;
          const isStatusFailed = status?.toUpperCase() === 'FAILED';
          return (
            <>
              {row?.exception && Array.isArray(row?.exception?.data) ? (
                <Link
                  sx={{
                    textDecorationColor: isStatusFailed
                      ? styles.color.red
                      : styles.color.darkShade,
                  }}
                  onClick={(ev) => {
                    if (isStatusFailed && exception?.data?.length > 0) {
                      setSelectedException(row?.exception);
                      setOpenExceptionsDrawer(true);
                    }
                  }}
                >
                  <OverflowTooltip text={ParseText(description)} />
                </Link>
              ) : (
                <OverflowTooltip text={ParseText(description)} />
              )}
            </>
          );
        },
      },
      {
        field: 'app',
        headerName: 'App',
        width: 170,
        renderCell: (params) => {
          const Icon =
            iconNameMap.hasOwnProperty(params.value?.toUpperCase()) &&
            iconNameMap[params.value?.toUpperCase()];
          return (
            <Stack direction="row">
              {Icon && (
                <Stack
                  width="20%"
                  sx={{
                    padding: '8px',
                    gap: '8px',
                    width: '36px',
                    height: '36px',
                    background: '#E7F3FA',
                    borderRadius: '20px',
                    alignItems: 'center',
                  }}
                >
                  <Icon width={26} darkBg={false} />
                </Stack>
              )}
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#08080D',
                }}
                m={1}
              >
                {params.value}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: 'startTime',
        headerName: 'Time',
        type: 'date',
        disableReorder: true,
        width: 130,
        renderCell: (params) => {
          const { row: data } = params;
          return (
            <Stack direction="column">
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#08080D',
                }}
              >
                From: {moment(data?.startTime).format(DF_HUMAN)}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: '#08080D',
                }}
              >
                To: {moment(data?.completionTime).format(DF_HUMAN)}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: 'initiatedBy',
        headerName: 'Initiated By',
        minWidth: 250,
        maxWidth: 320,

        renderCell: (params) => {
          const { row } = params;
          const { initiatedBy } = row;
          return <OverflowTooltip text={initiatedBy} />;
        },
      },
    ],
    [],
  );

  const filterProps = useMemo(
    () => [
      {
        name: 'Status',
        initialVal: 'All',
        options: [...((allFilters as any)?.status || []), 'All']
          ?.map((el) => ({ id: el, name: el }))
          ?.sort((a, b) => a.name - b.name),
        onChange: (value) => handleFilterChange('status', value),
      },
      {
        name: 'Entity',
        initialVal: 'All',
        options:
          [...((allFilters as any)?.entityType || []), 'All']
            ?.map((el) => ({ id: el, name: el }))
            ?.sort((a, b) => a.name - b.name) || defaultFilter,
        onChange: (value) => handleFilterChange('entity-type', value),
      },
      {
        name: 'App Family',
        initialVal: 'All',
        options:
          [...((allFilters as any)?.appFamily || []), 'All']
            ?.map((el) => ({ id: el, name: el }))
            ?.sort((a, b) => a.name - b.name) || defaultFilter,
        onChange: (value) => handleFilterChange('app-family', value),
      },
      {
        name: 'Initiated by',
        initialVal: 'All',
        options:
          [...((allFilters as any)?.initiatedBy || []), 'All']
            ?.map((el) => ({ id: el, name: el }))
            ?.sort((a, b) => a.name - b.name) || defaultFilter,
        onChange: (value) => handleFilterChange('initiated-by', value),
      },
      {
        name: 'Action',
        initialVal: 'All',
        options: [...((allFilters as any)?.actionType || []), 'All']
          ?.map((el) => ({ id: el, name: el }))
          ?.sort((a, b) => a.name - b.name),
        onChange: (value) => handleFilterChange('action-type', value),
      },
    ],
    [allFilters],
  );

  const searchProps = useMemo(
    () => ({
      name: 'Search',
      onChange: (value) => handleFilterChange('search-query', value),
    }),
    [],
  );
  const getTreeDataPath = (row) => row?.hierarchy;

  return (
    <Stack sx={{ height: '100vh' }}>
      <DataGrid
        treeData
        getTreeDataPath={getTreeDataPath}
        columns={dbColumns}
        rows={activities}
        loading={isLoading}
        styleOverrides={{ hideShadow: true }}
        pageCount={pageCount}
        pageNo={page}
        handlePaginationChange={handlePaginationChange}
        totalCount={activitiesCount}
        itemsPerPage={ITEMS_PER_PAGE}
        filters={filterProps}
        search={searchProps}
        groupingColDef={{
          headerName: '',
          width: '20',
          maxWidth: '20',
        }}
        components={{
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
      />
      <ActivityErrorDrawer
        openDrawer={openExceptionsDrawer}
        setOpenDrawer={setOpenExceptionsDrawer}
        exceptionsDataList={selectedException?.data}
      />
    </Stack>
  );
};

export default ActivityLog;
