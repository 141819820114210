import { useEffect } from 'react';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';

type TenantIntegrationsProps = {
  tenantId: string;
  tenantDomain: string;
};
const adaptTenantIntegrationsResponse = (response) => {
  return response?.response || [];
};
export const useTenantIntegrations = ({
  tenantId,
  tenantDomain,
}: TenantIntegrationsProps) => {
  const {
    response: data,
    isLoading,
    postData: fetchDBIntegrations,
  } = useLazyGetCallWithURL(adaptTenantIntegrationsResponse);

  useEffect(() => {
    if (tenantId && tenantDomain) {
      fetchDBIntegrations(`${URLS.fetchTenantIntegrations}tenant-id=${tenantId}&tenant-domain=${tenantDomain}
      `);
    }
  }, [tenantId, tenantDomain]);

  return { data, isLoading };
};
