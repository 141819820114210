import moment from 'moment';

import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { fetchMonitoringAdapter } from './utils/fetchMonitoringAdapter';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { TopProcessResponseType } from './TopProcesses/types/TopProcessTypes';
import { AdaptedMetricType } from './monitoringTypes';
import useInterval from 'helpers/useInterval';
import STATUS from '../Status';

interface FetchMetricsProps {
  serviceInstanceId: string;
  startTime: number;
  endTime: number;
  pgSource?: any;
  tenantId?: any;
  tenantDomain?: string;
  entityId?: any;
  instances?: any;
  instance?: any;
  step?: any;
  updateTimeUsingToggleButton?: (retainDisplayDuration?: boolean) => void;
  status?: string;
}

const adaptTopProcess = (
  topProcess: TopProcessResponseType,
): AdaptedMetricType[] => {
  const _Top = topProcess?.metrics?.map((metric) => {
    return {
      id: metric?.displayName || '',
      title: metric?.displayName,
      metricValues: metric?.metricValues,
      displayName: metric?.displayName,
      dataSets: [],
      entityType: 'NODE',
      maxVal: 0,
      minVal: 0,
      unit: metric?.unit || '',
    };
  });
  return _Top;
};

export const useFetchMetrics = (props: FetchMetricsProps) => {
  const {
    serviceInstanceId,
    startTime,
    endTime,
    pgSource,
    tenantId,
    tenantDomain,
    entityId,
    instances,
    instance,
    step,
    updateTimeUsingToggleButton,
  } = props;

  const [reset, setReset] = useState(true);

  const [combinedAdaptedResponse, setCombinedAdaptedResponse] = useState({
    dbMetrics: [],
    availability: [],
  });

  const st = moment(startTime)
    .utc()
    .format('YYYY-MM-DD[T]HH:mm:ss.SSSSSSSSS[Z]');
  const et = moment(endTime).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSSSSSSSS[Z]');

  const {
    postData: fetchNodeMetrics,
    response: nodeMetrics,
    responseStatus: fetchNodeResponseStatus,
    isLoading: areNodeMetricsLoading,
  } = useLazyGetCallWithURL();

  const {
    postData: fetchDbMetrics,
    response: dbMetrics,
    responseStatus: fetchDBResponseStatus,
    isLoading: areDbMetricsLoading,
  } = useLazyGetCallWithURL();

  const {
    postData: fetchTopProcesses,
    response: topProcess,
    responseStatus: fetchTopProcessStatus,
    isLoading: areTopProcessLoading,
  } = useLazyGetCallWithURL();

  const computeResourceId = useMemo(() => {
    return (
      instances?.find((obj) => obj?.id === serviceInstanceId)
        ?.computeResourceId || ''
    );
  }, [serviceInstanceId]);

  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (fetchNodeResponseStatus !== 200 && fetchNodeResponseStatus !== null) {
      addNotification({
        severity: 'error',
        message: 'Something went wrong.',
      });
    }
  }, [fetchNodeResponseStatus]);

  useEffect(() => {
    if (fetchDBResponseStatus !== 200 && fetchDBResponseStatus !== null) {
      addNotification({
        severity: 'error',
        message: 'Something went wrong.',
      });
    }
  }, [fetchDBResponseStatus]);

  const fetchAllMetrics = async () => {
    fetchTopProcesses(
      `${URLS.fetchTOPProcess}tenant-domain=${tenantDomain}&tenant-id=${tenantId}&compute-resource-id=${computeResourceId}&start-time=${st}&end-time=${et}&num-top-k=10`,
    );

    fetchNodeMetrics(
      `${
        URLS.fetchMonitoringDetails
      }?tenant-domain=${pgSource}&tenant-id=${tenantId}&service-id=${entityId}&entity-type=${'NODE'}&compute-resource-id=${computeResourceId}&service-instance-id=${instance}&start-time=${st}&end-time=${et}&step=${step}`,
    );
    fetchDbMetrics(
      `${
        URLS.fetchMonitoringDetails
      }?tenant-domain=${pgSource}&tenant-id=${tenantId}&service-id=${entityId}&entity-type=${'DB'}&compute-resource-id=${computeResourceId}&service-instance-id=${instance}&start-time=${st}&end-time=${et}&step=${step}`,
    );
    setReset((prev) => !prev);
  };

  useInterval(
    () => {
      if (status !== STATUS.Stopped) {
        updateTimeUsingToggleButton(false);
      }
    },
    30000,
    reset,
  );

  const adaptedTopProcess = useMemo(() => {
    return topProcess?.metrics?.length ? adaptTopProcess?.(topProcess) : [];
  }, [topProcess]);

  const combinedIsLoading = useMemo(
    () => areNodeMetricsLoading || areDbMetricsLoading,
    [areNodeMetricsLoading, areDbMetricsLoading, areTopProcessLoading],
  );

  useLayoutEffect(() => {
    if (
      !combinedIsLoading &&
      Array.isArray(dbMetrics) &&
      Array.isArray(nodeMetrics) &&
      Array.isArray(adaptedTopProcess)
    ) {
      const res = fetchMonitoringAdapter([...nodeMetrics, ...dbMetrics]);
      setCombinedAdaptedResponse({
        ...res,
        dbMetrics: [...res.dbMetrics, ...adaptedTopProcess],
      });
    }
  }, [dbMetrics, nodeMetrics, combinedIsLoading, adaptedTopProcess]);

  return {
    response: combinedAdaptedResponse,
    isLoading: combinedIsLoading,
    postData: fetchAllMetrics,
  };
};
