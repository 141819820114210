import { Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import UpdateForm from './UpdateForm';
import OtpModal from 'common/Modals/OtpModal';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import useUpdateMetadata from './useUpdateMetadata';
import { OTP_TYPE } from 'constants/otpType';
import { DialogTitleString } from './constants';
import { styles } from 'assets/scss/style-templates';
import { tenantPhases } from 'customers/tenants-detailed-view/lib/components/onboarding/constants';

type UpdateMetadataType = {
  open: boolean;
  setOpen: Function;
  entityType: string;
  entityName: string;
  tenantId: string;
  tenantDomain: string;
  entityId: string;
  parentEntityId?: string;
  handleSuccess?: any;
};

const UpdateMetadata = (props: UpdateMetadataType) => {
  const { open, entityName, entityType } = props;
  const {
    response,
    payload,
    openOTP,
    data,
    fetchIsLoading,
    sendOTPIsLoading,
    metadataUpdateIsLoading,
    step,
    onClose,
    setOpenOTP,
    setPayLoad,
    setStep,
    modalSubmitHandler,
    handleUpdateClick,
    handleFetchLastest,
    options,
  } = useUpdateMetadata(props);

  // Dialog
  const DialogTitle = () => (
    <>{`Update ${
      DialogTitleString?.[entityType] || 'Metadata'
    } for ${entityName}`}</>
  );

  const toShowPOCtoLiveWarning =
    entityType === 'tenant' &&
    payload?.oldData?.tenantPhase === tenantPhases.POC &&
    payload?.newData?.tenantPhase === tenantPhases.LIVE;
  const ShowPOCtoLiveWarning = () => (
    <Stack>
      <Typography variant="body2" color={styles.color.warn}>
        This action will remove all previous POC owners.
      </Typography>
    </Stack>
  );
  const DialogContent = () => (
    <>
      {toShowPOCtoLiveWarning && <ShowPOCtoLiveWarning />}
      <UpdateForm
        updateList={response && response[entityType]}
        data={data}
        payload={payload}
        setPayLoad={setPayLoad}
        step={step}
        dropDownOptions={options}
      />
    </>
  );

  const DialogActions = () => (
    <Stack width="100%" direction="row" justifyContent="flex-start" mt={2}>
      <Stack spacing={1} direction="row" justifyContent="flex-end" width="100%">
        <Button onClick={onClose} variant="outlined" size="small">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          loading={fetchIsLoading}
          onClick={handleFetchLastest}
        >
          Fetch Latest
        </LoadingButton>
        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          onClick={handleUpdateClick}
        >
          {step === 0 ? 'Edit' : 'Update'}
        </LoadingButton>
      </Stack>
    </Stack>
  );
  const renderFunctions = {
    title: DialogTitle,
    content: DialogContent,
    actions: DialogActions,
  };

  return (
    <>
      <TessellModal
        renderFunctions={renderFunctions}
        maxWidth="sm"
        open={open}
        onClose={onClose}
      />

      <OtpModal
        open={openOTP}
        setOpen={setOpenOTP}
        setStep={setStep}
        onSubmit={modalSubmitHandler}
        isSubmitting={step === 1 ? sendOTPIsLoading : metadataUpdateIsLoading}
        otpUser="Ops admin"
        step={step}
        reasonSubmitBtnText="Update metadata"
        reasonHeading="Enter Reason (with JIRA link)"
        otpType={OTP_TYPE.UPDATE_METADATA}
      />
    </>
  );
};

export default UpdateMetadata;
