import { styles } from 'assets/scss/style-templates';
import STATUS from 'customers/db-services-detailed-view/Status';

import {
  FiberManualRecord,
  ArrowUpward,
  ArrowDownward,
} from '@mui/icons-material';

export const StatusTextColor = {
  [STATUS.Ready]: styles.color.baseBackground,
  [STATUS.Up]: styles.color.green,
  [STATUS.Down]: styles.color.baseBackground,
  [STATUS.Stopped]: styles.color.red,
  [STATUS.Running]: styles.color.green,
  [STATUS.Deleting]: styles.color.red,
  [STATUS.Deleted]: styles.color.red,
  [STATUS.Starting]: styles.color.baseBackground,
  [STATUS.Stopping]: styles.color.yellow,
  [STATUS.Creating]: styles.color.baseBackground,
  [STATUS.Failed]: styles.color.baseBackground,
  [STATUS.Switching]: styles.color.baseBackground,
  [STATUS.Rebuilding]: styles.color.baseBackground,
  [STATUS.Degraded]: styles.color.baseBackground,
  [STATUS.Active]: styles.color.baseBackground,
  [STATUS.Updating]: styles.color.green,
  [STATUS.DegradedHealing]: styles.color.baseBackground,
  [STATUS.DeletionFailed]: styles.color.baseBackground,
  [STATUS.VmStopFailed]: styles.color.red,
  [STATUS.Invited]: styles.color.green,
  [STATUS.Inactive]: styles.color.baseBackground,
  [STATUS.resolved]: styles.color.green,
  [STATUS.warning]: styles.color.red,
  [STATUS.StartFailed]: styles.color.baseBackground,
  [STATUS.StopFailed]: styles.color.red,
  [STATUS.CreationFailed]: styles.color.red,
  [STATUS.SwitchoverFailed]: styles.color.red,
  [STATUS.PatchFailed]: styles.color.red,
  [STATUS.RestoreFailed]: styles.color.red,
  [STATUS.ConnectionStopFailed]: styles.color.red,
  [STATUS.Healing]: styles.color.baseBackground,
  [STATUS.WarmStopped]: styles.color.red,
  [STATUS.ToBeDeleted]: styles.color.warn,
  [STATUS.DeletedForUser]: styles.color.red,
  [STATUS.SwitchingOver]: styles.color.baseBackground,
  [STATUS.Restoring]: styles.color.baseBackground,
  [STATUS.BackingUp]: styles.color.baseBackground,
  [STATUS.Patching]: styles.color.baseBackground,
  [STATUS.Firing]: styles.color.red,
  [STATUS.resolved]: styles.color.baseBackground,
  [STATUS.ConnectionStopped]: styles.color.red,
  [STATUS.critical]: styles.color.baseBackground,
  [STATUS.Urgent]: styles.color.baseBackground,
  [STATUS.High]: styles.color.baseBackground,
  [STATUS.Medium]: styles.color.baseBackground,
  [STATUS.Low]: styles.color.baseBackground,
  [STATUS.Open]: styles.color.baseBackground,
  [STATUS.Closed]: styles.color.baseBackground,
  [STATUS.Assigned]: styles.color.baseBackground,
  [STATUS.Fixed]: styles.color.baseBackground,
  [STATUS.Resolved]: styles.color.baseBackground,
  [STATUS.Duplicate]: styles.color.baseBackground,
  [STATUS.Investigating]: styles.color.baseBackground,
  [STATUS.Leak]: styles.color.baseBackground,
  [STATUS.SUCCESS]: styles.color.baseBackground,
  [STATUS.FAILED]: styles.color.baseBackground,
  [STATUS.Unknown]: styles.color.baseBackground,
};

export const StatusBorderColor = {
  [STATUS.Ready]: styles.color.green,
  [STATUS.Up]: styles.color.green,
  [STATUS.Down]: '#FFEBEB',
  [STATUS.Stopped]: '#FEF2D8',
  [STATUS.Running]: styles.color.green,
  [STATUS.Deleting]: styles.color.red,
  [STATUS.Deleted]: styles.color.red,
  [STATUS.Starting]: styles.color.green,
  [STATUS.Stopping]: styles.color.yellow,
  [STATUS.Creating]: styles.color.green,
  [STATUS.Failed]: styles.color.red,
  [STATUS.Switching]: styles.color.green,
  [STATUS.Rebuilding]: styles.color.green,
  [STATUS.Degraded]: styles.color.red,
  [STATUS.Active]: styles.color.green,
  [STATUS.Updating]: styles.color.green,
  [STATUS.DegradedHealing]: styles.color.green,
  [STATUS.DeletionFailed]: styles.color.red,
  [STATUS.VmStopFailed]: styles.color.red,
  [STATUS.Invited]: styles.color.green,
  [STATUS.Inactive]: styles.color.red,
  [STATUS.resolved]: styles.color.green,
  [STATUS.warning]: styles.color.red,
  [STATUS.StartFailed]: styles.color.red,
  [STATUS.StopFailed]: styles.color.red,
  [STATUS.CreationFailed]: styles.color.red,
  [STATUS.SwitchoverFailed]: styles.color.red,
  [STATUS.PatchFailed]: styles.color.red,
  [STATUS.RestoreFailed]: styles.color.red,
  [STATUS.ConnectionStopFailed]: styles.color.red,
  [STATUS.Healing]: styles.color.green,
  [STATUS.WarmStopped]: styles.color.green,
  [STATUS.ToBeDeleted]: styles.color.green,
  [STATUS.DeletedForUser]: styles.color.red,
  [STATUS.SwitchingOver]: styles.color.green,
  [STATUS.Restoring]: styles.color.green,
  [STATUS.BackingUp]: styles.color.green,
  [STATUS.Patching]: styles.color.green,
  [STATUS.Firing]: styles.color.red,
  [STATUS.resolved]: styles.color.green,
  [STATUS.ConnectionStopped]: styles.color.warningBackground,
  [STATUS.critical]: styles.color.red,
  [STATUS.Open]: styles.color.warn,
  [STATUS.Closed]: styles.color.green,
  [STATUS.Assigned]: styles.color.baseBackground,
  [STATUS.Fixed]: styles.color.baseBackground,
  [STATUS.Resolved]: styles.color.baseBackground,
  [STATUS.Duplicate]: styles.color.baseBackground,
  [STATUS.Investigating]: styles.color.baseBackground,
  [STATUS.Leak]: styles.color.red,
  [STATUS.SUCCESS]: styles.color.green,
  [STATUS.FAILED]: styles.color.red,
  [STATUS.Unknown]: styles.color.warn,
  [STATUS.REFRESH_FAILED]: styles.color.red,
  [STATUS.UPDATING_PARAMETER_PROFILE]: styles.color.warn,
  [STATUS.UNDER_MAINTENANCE]: styles.color.yellow,
  [STATUS.PARAMETER_PROFILE_UPDATE_FAILED]: styles.color.red,
  [STATUS.REFRESHING]: styles.color.warn,

};

export const StatusBackgroundColor = {
  [STATUS.Ready]: styles.color.green,
  [STATUS.Up]: styles.color.baseBackground,
  [STATUS.Down]: styles.color.red,
  [STATUS.Successful]: styles.color.green,
  [STATUS.Unsuccessful]: styles.color.red,
  [STATUS.Requested]: styles.color.warn,
  [STATUS.Stopped]: '#FEF2D8',
  [STATUS.Running]: '#F0FCF0',
  [STATUS.Deleting]: styles.color.warningBackground,
  [STATUS.Deleted]: styles.color.warningBackground,
  [STATUS.Starting]: styles.color.green,
  [STATUS.Stopping]: styles.color.baseBackground,
  [STATUS.Creating]: styles.color.green,
  [STATUS.Failed]: styles.color.red,
  [STATUS.Switching]: styles.color.green,
  [STATUS.Rebuilding]: styles.color.green,
  [STATUS.Degraded]: styles.color.red,
  [STATUS.Active]: styles.color.green,
  [STATUS.Updating]: styles.color.green,
  [STATUS.DegradedHealing]: styles.color.yellow,
  [STATUS.DeletionFailed]: styles.color.red,
  [STATUS.VmStopFailed]: styles.color.baseBackground,
  [STATUS.Invited]: styles.color.baseBackground,
  [STATUS.Inactive]: styles.color.red,
  [STATUS.resolved]: styles.color.green,
  [STATUS.warning]: styles.color.warningBackground,
  [STATUS.StartFailed]: styles.color.red,
  [STATUS.StopFailed]: styles.color.green,
  [STATUS.CreationFailed]: styles.color.baseBackground,
  [STATUS.SwitchoverFailed]: styles.color.baseBackground,
  [STATUS.PatchFailed]: styles.color.baseBackground,
  [STATUS.RestoreFailed]: styles.color.baseBackground,
  [STATUS.ConnectionStopFailed]: styles.color.baseBackground,
  [STATUS.Healing]: styles.color.green,
  [STATUS.WarmStopped]: styles.color.baseBackground,
  [STATUS.ToBeDeleted]: styles.color.baseBackground,
  [STATUS.DeletedForUser]: styles.color.baseBackground,
  [STATUS.SwitchingOver]: styles.color.green,
  [STATUS.Restoring]: styles.color.green,
  [STATUS.BackingUp]: styles.color.green,
  [STATUS.Patching]: styles.color.green,
  [STATUS.Firing]: styles.color.baseBackground,
  [STATUS.ConnectionStopped]: styles.color.warningBackground,
  [STATUS.critical]: styles.color.red,
  [STATUS.Urgent]: styles.color.red,
  [STATUS.High]: styles.color.lighterRed,
  [STATUS.Medium]: styles.color.warn,
  [STATUS.Low]: styles.color.yellow,
  [STATUS.Open]: styles.color.warn,
  [STATUS.Closed]: styles.color.green,
  [STATUS.Assigned]: styles.color.yellow,
  [STATUS.Fixed]: styles.color.green,
  [STATUS.Resolved]: styles.color.green,
  [STATUS.Duplicate]: styles.color.yellow,
  [STATUS.Investigating]: styles.color.lighterRed,
  [STATUS.Leak]: styles.color.red,
  [STATUS.SUCCESS]: styles.color.green,
  [STATUS.FAILED]: styles.color.red,
  [STATUS.Unknown]: styles.color.warn,
};

export const StatusIcons = {
  [STATUS.Up]: ArrowUpward,
  [STATUS.Down]: ArrowDownward,
  [STATUS.Stopped]: FiberManualRecord,
};
