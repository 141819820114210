import { useNavigate, useParams } from 'react-router-dom';
import { TabType } from 'common/tab-view/lib/schema/TabType';
import { TabView } from 'common/tab-view/lib/components/TabView';
import _isEmpty from 'lodash/isEmpty';
import {
  AlertIcon,
  ExclamationIcon,
  InfrastructureIcon,
  MonitoringIcon,
} from 'assets/icons-v2';
import SilenceAlerts from './Alerts/SilenceAlerts';
import Exceptions from './Exceptions/Exceptions';
import { styles } from 'assets/scss/style-templates';
import PGSyncMonitoring from './Monitoring/PGSyncMonitoring';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { useContext } from 'react';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import { Alerts } from 'customers/tenants-detailed-view/lib/components/alerts/Alerts';
import Downloads from './Downloads/Downloads';
import { DownloadDone } from '@mui/icons-material';

import Leaks from './Leaks/Leaks';
import InfraMonitoring from 'Infrastructure/CloudInfrastructure/InfraMonitoring/InfraMonitoring';
import { ENV, ENV_VARIABLES } from 'constants/env';
import Features from './Features/Features';

enum PortalAdministrationTabType {
  SilenceAlerts = 'Silenced Alerts',
  Exceptions = 'Exceptions',
  OpsMonitoring = 'Ops Monitoring',
  InfraMonitoring = 'Infra Monitoring',
  Alerts = 'Alerts',
  Downloads = 'Downloads',
  Leaks = 'Leaks',
  Features = 'Features',
}

// Snake to Camel case
const RouteToPortalAdministrationTabMap = {
  'silenced-alerts': 'Silenced Alerts',
  exceptions: 'Exceptions',
  'ops-monitoring': 'Ops Monitoring',
  'infra-monitoring': 'Infra Monitoring',
  alerts: 'Alerts',
  downloads: 'Downloads',
  leaks: 'Leaks',
  features: 'Features',
};

// Camel case to Snake case
const PortalAdministrationTabToRouteMap = {
  'Silenced Alerts': 'silenced-alerts',
  Exceptions: 'exceptions',
  'Ops Monitoring': 'ops-monitoring',
  'Infra Monitoring': 'infra-monitoring',
  Alerts: 'alerts',
  Downloads: 'downloads',
  Leaks: 'leaks',
  Features: 'features',
};

export const PortalAdministrationTabView = (): JSX.Element => {
  const navigate = useNavigate();

  const params = useParams();
  const { hasPermission } = useContext(UserPermissionsContext);

  const { tabId = 'alerts' } = params;
  const onTabClick = (event: any, newValue: string) => {
    navigate(
      `/portal-administration/${PortalAdministrationTabToRouteMap[newValue]}`,
      {
        replace: true,
      },
    );
  };

  const tabValue = RouteToPortalAdministrationTabMap[
    tabId
  ] as PortalAdministrationTabType;

  const tabsProps: TabType[] = [
    hasPermission(PERMISSIONS.ALERTS_VIEW) && {
      label: PortalAdministrationTabType.Alerts,
      tabValue: PortalAdministrationTabType.Alerts,
      render: () => <Alerts />,
      icon: <InfrastructureIcon height={16} color={styles.color.darkShade} />,
    },
    hasPermission(PERMISSIONS.ALERTS_SILENCE) && {
      label: PortalAdministrationTabType.SilenceAlerts,
      tabValue: PortalAdministrationTabType.SilenceAlerts,
      render: () => {
        return <SilenceAlerts />;
      },
      icon: <AlertIcon />,
    },
    hasPermission(PERMISSIONS.INFRA_MONITORING) && {
      label: PortalAdministrationTabType.Exceptions,
      tabValue: PortalAdministrationTabType.Exceptions,
      render: () => {
        return <Exceptions />;
      },
      icon: <ExclamationIcon />,
    },
    ENV_VARIABLES[ENV].LEAKS &&
      hasPermission(PERMISSIONS.INFRA_MONITORING) && {
        label: PortalAdministrationTabType.Leaks,
        tabValue: PortalAdministrationTabType.Leaks,
        render: () => {
          return <Leaks />;
        },
        icon: <ExclamationIcon />,
      },
    hasPermission(PERMISSIONS.INFRA_MONITORING) && {
      label: PortalAdministrationTabType.OpsMonitoring,
      tabValue: PortalAdministrationTabType.OpsMonitoring,
      render: () => {
        return <PGSyncMonitoring />;
      },
      icon: <MonitoringIcon height={16} color={styles.color.darkShade} />,
    },
    // hasPermission(PERMISSIONS.INFRA_MONITORING) && {
    //   label: PortalAdministrationTabType.InfraMonitoring,
    //   tabValue: PortalAdministrationTabType.InfraMonitoring,
    //   render: () => {
    //     return <InfraMonitoring />;
    //   },
    //   icon: <MonitoringIcon height={16} color={styles.color.darkShade} />,
    // },
    hasPermission(PERMISSIONS.DOWNLOAD_TAB_ACCESS) && {
      label: PortalAdministrationTabType.Downloads,
      tabValue: PortalAdministrationTabType.Downloads,
      render: () => {
        return <Downloads />;
      },
      icon: <DownloadDone color="primary" />,
    },
    // hasPermission(PERMISSIONS.FEATURE) && {
    //   label: PortalAdministrationTabType.Features,
    //   tabValue: PortalAdministrationTabType.Features,
    //   render: () => {
    //     return <Features />;
    //   },
    //   icon: null,
    // },
  ].filter(Boolean);

  const currentTab =
    tabsProps?.find((tab) => tab?.tabValue === tabValue) || tabsProps?.[0];

  return (
    <>
      {
        <TabView
          tabs={tabsProps}
          onTabClick={onTabClick}
          currentTab={currentTab}
        />
      }
    </>
  );
};
