import { CloseRounded } from '@mui/icons-material';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { TessellDrawer } from '@tessell/tessell-common-ui-library';
import { activityErrorDrawerStyles } from './styles';
import FailedActivity from 'assets/icons-v2/FailedActivity';
import { ActivityErrorDetails } from './ActivityErrorDetails';

type UserView = {
  message: string;
  resolution: string;
};

type ExceptionData = {
  tessellErrorCode: string;
  isInternal: boolean;
  occurredAt: string;
  contextId: string;
  sessionId: string;
  message: string;
  resolution: string;
  externalStackTrace: string;
  userView: UserView;
};

interface ActivityErrorDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: any;
  exceptionsDataList: ExceptionData[];
}

const ActivityErrorDrawer = (props: ActivityErrorDrawerProps) => {
  const { openDrawer, setOpenDrawer, exceptionsDataList } = props;
  const exceptionData = exceptionsDataList?.[0];
  const {
    contextId = '',
    message = '',
    resolution = '',
    externalStackTrace = '',
    userView,
  } = exceptionData || {};

  const errorDetails = [
    { title: 'Error Details', info: message },
    { title: 'Possible Solutions', info: resolution },
    { title: 'Context Id', info: contextId },
    { title: 'External Stack Trace', info: externalStackTrace },
  ];

  const userViewDetails = [
    { title: 'Error Details', info: userView?.message },
    { title: 'Possible Solutions', info: userView?.resolution },
  ];

  return (
    <TessellDrawer open={openDrawer} onCloseDrawer={() => setOpenDrawer(false)}>
      <Stack sx={activityErrorDrawerStyles.container}>
        <Stack sx={activityErrorDrawerStyles.headerContainer}>
          <Typography sx={activityErrorDrawerStyles.errorDetailsText}>
            Error Details
          </Typography>
          <IconButton
            size="small"
            onClick={(ev) => {
              ev.stopPropagation();
              setOpenDrawer(false);
            }}
          >
            <CloseRounded sx={activityErrorDrawerStyles.closeButton} />
          </IconButton>
        </Stack>
        <Divider />
        <Stack sx={activityErrorDrawerStyles.failedActivityContainer}>
          <FailedActivity />
        </Stack>
        <Stack sx={activityErrorDrawerStyles.errorDetailsContainer}>
          {errorDetails?.map((_details) => (
            <ActivityErrorDetails
              title={_details?.title}
              details={_details?.info}
            />
          ))}
        </Stack>
        <Stack sx={activityErrorDrawerStyles.userViewContainer}>
          <Typography sx={activityErrorDrawerStyles.userViewText}>
            {' '}
            User View
          </Typography>
        </Stack>
        <Stack sx={activityErrorDrawerStyles.errorDetailsContainer}>
          {userViewDetails?.map((_details) => (
            <ActivityErrorDetails
              title={_details?.title}
              details={_details?.info}
            />
          ))}
        </Stack>
      </Stack>
    </TessellDrawer>
  );
};

export default ActivityErrorDrawer;
