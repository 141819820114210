export const adaptVpcEndpointMetadata = (data) => {
  const { vpcEndpoints } = data || {};
  const vpcEndpointData = Object.keys(vpcEndpoints || {}).map((key, i) => {
    const vpcEndpoint = vpcEndpoints?.[key];
    return {
      id: i,
      targetServiceType: vpcEndpoint?.targetServiceType,
      privateDnsEnabled: vpcEndpoint?.privateDnsEnabled,
      dnsEntries: vpcEndpoint?.dnsEntries?.map((entry) => entry?.dnsName) || [
        '-',
      ],
    };
  });
  return vpcEndpointData;
};
