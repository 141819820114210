import { useContext, useEffect, useMemo, useState } from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import moment from 'moment';
import { DF_HUMAN } from 'helpers/dateFormats';
import { useGetCall, useLazyDeleteCallWithURL } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { adaptSilenceAlertsResponse } from './adaptSilenceAlertsResponse';
import { SilenceAlertsModal } from 'common/Modals/SilenceAlertsModal/SilenceAlertsModal';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import ConfirmationDialog from 'common/Modals/ConfirmationDialog';

const SilenceAlerts = () => {
  const [openModal, setOpenModal] = useState(false);
  const [multiple, setMultiple] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const { addNotification } = useContext(NotificationContext);

  const {
    response: alerts,
    isLoading,
    postData: fetchAlerts,
  } = useGetCall(URLS.getAlertSilences, adaptSilenceAlertsResponse);

  const {
    response: expireAlertResponse,
    isLoading: expireAlertLoading,
    postData: expireAlert,
    responseStatus: expireAlertResponseStatus,
    error: expireAlertError,
  } = useLazyDeleteCallWithURL();

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        hide: true,
      },
      {
        field: 'startsAt',
        headerName: 'Start At',
        width: 150,
        renderCell: (params) => {
          const { row: data } = params;
          const { startsAt } = data;
          const _date = moment(startsAt).format(DF_HUMAN);
          if (!_date) {
            return '';
          }
          return <Stack>{_date}</Stack>;
        },
      },
      {
        field: 'endsAt',
        headerName: 'Ends At',
        width: 150,
        renderCell: (params) => {
          const { row: data } = params;
          const { endsAt } = data;
          const _date = moment(endsAt).format(DF_HUMAN);
          if (!_date) {
            return '';
          }
          return <Stack>{_date}</Stack>;
        },
      },

      {
        field: 'matchers',
        headerName: 'Matchers',
        width: 300,
        renderCell: (params) => {
          const { row: data } = params;
          const { matchers } = data;
          return matchers?.length ? (
            <Stack direction="row" flexWrap="wrap" spacing={1}>
              {matchers?.map((_p) => (
                <Stack py={1}>
                  <Chip label={`${_p?.name}: ${_p?.value}`} />
                </Stack>
              ))}
            </Stack>
          ) : (
            '-'
          );
        },
      },
      {
        field: 'creator',
        headerName: 'Created by',
      },
    ],
    [],
  );

  const actionProps = useMemo(
    () => [
      {
        label: 'Silence Alert',
        onClick: (e) => {
          setOpenModal(true);
        },
      },
      {
        label: 'Bulk Silence Alerts',
        onClick: (e) => {
          setMultiple(true);
          setOpenModal(true);
        },
      },
    ],
    [],
  );

  const menuItemProps = useMemo(
    () => [
      {
        label: 'Unsilence Alert',
        onClick: (_alert) => {
          setSelectedAlert(_alert);
          setOpenDeleteConfirmation(true);
        },
      },
    ],
    [],
  );

  useEffect(() => {
    if (expireAlertResponse?.status === 'SUCCESSFUL') {
      addNotification({
        severity: 'success',
        message: `${expireAlertResponse?.message}`,
      });
      setOpenDeleteConfirmation(false);
      fetchAlerts();
    } else if (expireAlertResponse?.status) {
      addNotification({
        severity: 'error',
        message: expireAlertResponse?.message || 'Something went wrong',
      });
      setOpenDeleteConfirmation(false);
    } else if (
      expireAlertResponse === null &&
      expireAlertResponseStatus > 200
    ) {
      addNotification({
        severity: 'error',
        message: expireAlertError?.message,
      });
      setOpenDeleteConfirmation(false);
    }
  }, [expireAlertResponse, expireAlertResponseStatus, expireAlertError]);

  const handleOnSuccess = () => {
    setOpenModal(false);
    fetchAlerts();
  };

  const onClose = (val) => {
    setOpenModal(val);
    setMultiple(false);
  };

  return (
    <Stack sx={{ height: '100vh' }}>
      <DataGrid
        columns={columns}
        rows={alerts}
        loading={isLoading}
        styleOverrides={{ hideShadow: true }}
        actions={actionProps}
        menuItems={menuItemProps}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
      />
      {openModal && (
        <SilenceAlertsModal
          open={openModal}
          setOpen={onClose}
          handleOnSuccess={handleOnSuccess}
          bulkSilence={multiple}
        />
      )}

      <ConfirmationDialog
        title="Unsilence Alert"
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        handleConfirm={() => {
          expireAlert(`${URLS.expireSilence}${selectedAlert?.id}`);
        }}
        confirmContent={
          <Typography>
            Are you sure you want to unsilence this alert?
          </Typography>
        }
        isLoading={expireAlertLoading}
      />
    </Stack>
  );
};

export default SilenceAlerts;
