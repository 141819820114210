import { Auth } from 'aws-amplify';
import { currentAwsConfig } from './awsConfigure';

export const multiFactorAuth = async (
  user,
  otp,
  setUserLoginInfo,
  navigate,
  setVerifyLoading,
  addNotification,
) => {
  const awsConfig = currentAwsConfig;
  setVerifyLoading(true);
  try {
    await Auth.signOut();
    const userSignIn = await Auth.confirmSignIn(user, otp, user?.challengeName)
      ?.then((user) => {
        if (user?.signInUserSession?.accessToken?.jwtToken) {
          setUserLoginInfo(user);
          setVerifyLoading(false);
          navigate('/dashboard');
        }

        return user;
      })
      .catch((error) => {
        console.log('Error Signing in:', new Error());
        addNotification({
          severity: 'error',
          message: error?.message || 'Something went wrong',
        });
        setVerifyLoading(false);
      });
    return userSignIn;
  } catch (error) {
    return error;
  }
};
