import { useGetCall, useLazyGetCall } from 'common/api/useApiCall';
import { useDataAndCount } from 'common/Hooks/useDataAndCount';
import { URLS } from 'constants/URL';
import { useEffect, useState } from 'react';
import { adaptActivityLogWithChild } from './adaptActivityLog';

export const useActivityLog = (props) => {
  const { tenant } = props;
  const { pgSource, _id: tenantId, emailAddress } = tenant;
  const [allFilters, setAllFilters] = useState({});
  const [activities, setActivities] = useState([]);
  const [filters, setFilters] = useState({
    'tenant-domain': pgSource,
    'tenant-id': tenantId,
    'user-id': emailAddress,
    status: 'All',
    'entity-type': 'All',
    'action-type': 'All',
    'app-family': 'All',
    'initiated-by': 'All',
    'load-child-activities': true,
  });
  const defaultFilter = [
    { name: 'All', id: 'All', 'load-child-activities': true },
  ];
  const { response: filtersResp, isLoading } = useGetCall(
    `${URLS.fetchActivityLogFilters}tenant-id=${tenantId}&tenant-domain=${pgSource}`,
  );

  const [openExceptionsDrawer, setOpenExceptionsDrawer] = useState(false);
  const [selectedException, setSelectedException] = useState({} as any);

  useEffect(() => {
    setAllFilters(filtersResp || {});
  }, [filtersResp]);

  const {
    count,
    pageCount,
    dataResponse,
    dataResponseStatus: getActivitiesResStatus,
    dataIsLoading: activitiesIsLoading,
    countIsLoading: activitiesCountIsLoading,
    page,
    handlePaginationChange,
    fetchDataAndCount: getActivities,
    setPage,
  } = useDataAndCount({
    url: URLS.fetchActivityLogs,
    dataResponseAdapter: adaptActivityLogWithChild,
    params: filters,
  });

  useEffect(() => {
    setActivities(dataResponse);
  }, [dataResponse]);

  const handleFilterChange = (filterKey, val) => {
    setPage(1);
    setFilters((prev) => ({ ...prev, [filterKey]: val }));
  };

  return {
    activities,
    // isLoading: activitiesIsLoading || activitiesCountIsLoading,
    isLoading: activitiesIsLoading,
    page,
    pageCount,
    allFilters,
    activitiesCount: count,
    defaultFilter,
    selectedException,
    openExceptionsDrawer,
    setPage,
    handlePaginationChange,
    handleFilterChange,
    setOpenExceptionsDrawer,
    setSelectedException,
  };
};
