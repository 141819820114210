import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { evaluateTenantName } from 'common/TenantContainer/evaluateTenantName';
import {
  useLazyGetCallWithURL,
  useLazyPostCall,
  useLazyPutCall,
} from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { OTPRequestType } from 'constants/URLEnums';
import { OTP_TYPE } from 'constants/otpType';
import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { useContext, useEffect, useState } from 'react';

type useToggleBillingProps = {
  tenant: TenantType;
  handleToggleBillingSuccess?: any;
  metadata: any;
};

export const useToggleBilling = (props: useToggleBillingProps) => {
  const { tenant, handleToggleBillingSuccess, metadata: propsMetdata } = props;
  const { _id, metadata, pgSource } = tenant;

  const currentBillingStatus =
    metadata?.billingInfo?.skipBillGeneration === false;

  const [openOTP, setOpenOTP] = useState(false);
  const [otpType, setOtpType] = useState('');
  const [toggledFlag, setToggledFlag] = useState([]);
  const [flags, setFlags] = useState([]);

  const [step, setStep] = useState(1);
  const [uuid, setUuid] = useState('');
  const { addNotification } = useContext(NotificationContext);

  const { response: flagsResp, postData } = useLazyGetCallWithURL();

  useEffect(() => {
    postData(URLS.getTenantFeatureFlags.replace('{id}', _id));
  }, []);

  useEffect(() => {
    if (flagsResp?.response?.length) {
      setFlags(flagsResp?.response || []);
    }
  }, [flagsResp]);

  const {
    response: sendOTPRes,
    isLoading: sendOTPIsLoading,
    postData: sendOTP,
  } = useLazyPostCall(`${URLS.otp}purpose=${otpType}`);

  const {
    response: toggleBillingResponse,
    postData: toggleBillingPostData,
    isLoading: toggleBillingIsLoading,
    responseStatus: toggleBillingResponseStatus,
  } = useLazyPutCall(
    `${URLS.toggleBilling}tenant-id=${_id}&tenant-domain=${pgSource}`,
  );

  const {
    response: toggleFlagResponse,
    postData: toggleFlagPostData,
    isLoading: toggleFlagIsLoading,
    responseStatus: toggleFlagResponseStatus,
  } = useLazyPutCall(
    `${URLS.updateTenantFeatureFlags.replace('{id}', _id)}`,
    null,
    true,
  );

  useEffect(() => {
    if (toggleBillingResponseStatus === 200 && toggleBillingResponse?.message) {
      addNotification({
        severity: 'success',
        message: `${toggleBillingResponse?.message}`,
      });
      handleToggleBillingSuccess();
      setOpenOTP(false);
      setStep(1);
    } else if (
      toggleBillingResponseStatus &&
      toggleBillingResponseStatus !== 200
    ) {
      addNotification({
        severity: 'error',
        message: 'Something went wrong, please try again.',
      });
    }
  }, [toggleBillingResponse]);

  useEffect(() => {
    if (toggleFlagResponseStatus === 200 && toggleFlagResponse?.message) {
      addNotification({
        severity: 'success',
        message: `${toggleFlagResponse?.message}`,
      });
      handleToggleBillingSuccess();
      setOpenOTP(false);
      setStep(1);
    } else if (toggleFlagResponseStatus && toggleFlagResponseStatus !== 200) {
      addNotification({
        severity: 'error',
        message: 'Something went wrong, please try again.',
      });
    }
    setOtpType('');
    setToggledFlag([]);
  }, [toggleFlagResponse]);

  const sendOTPCall = ({ comment, jiraId }) => {
    let payload = {};
    if (otpType === OTPRequestType.BILLING_OTP) {
      payload = {
        'tenant-id': _id,
        comment: comment,
        jiraId: jiraId,
        'tenant-domain': pgSource,
        'new-billing-status': !currentBillingStatus,
        'old-billing-status': currentBillingStatus,
        'tenant-name': evaluateTenantName({
          tenant: tenant,
        }),
      };
    } else if (otpType === OTPRequestType.TENANT_FLAG_TOGGLE_OTP) {
      payload = {
        tenantId: _id,
        comment: comment,
        jiraId: jiraId,
        newFlagValue: !toggledFlag[1],
        flagName: toggledFlag[0],
      };
    }
    sendOTP(JSON.stringify(payload, null));
  };

  useEffect(() => {
    if (sendOTPRes?.uuid) {
      setUuid(sendOTPRes?.uuid);
      setStep(2);
    }
  }, [sendOTPRes]);

  const getTitleAndReason = () => {
    if (otpType === OTPRequestType.BILLING_OTP) {
      return {
        title: `${
          !currentBillingStatus ? 'Enable' : 'Disable'
        } billing for ${evaluateTenantName({
          tenant: tenant,
        })}`,
        subTitle:
          propsMetdata?.infraInfo?.deploymentType === 'PROFESSIONAL'
            ? propsMetdata?.infraInfo?.emailAddress
            : null,
        reasonHeading: 'Enter reason for changing billing status.',
        otpType:
          propsMetdata?.billingInfo?.skipBillGeneration === false
            ? OTP_TYPE.DISABLE_BILLING
            : OTP_TYPE.ENABLE_BILLING,
      };
    } else if (otpType === OTPRequestType.TENANT_FLAG_TOGGLE_OTP) {
      return {
        title: `${toggledFlag[1] ? 'Disable' : 'Enable'} ${
          toggledFlag[0]
        } for ${evaluateTenantName({
          tenant: tenant,
        })}`,
        subTitle: null,
        reasonHeading:
          'Enter reason for changing the tenant feature flag status.',
        otpType: OTP_TYPE.TENANT_FLAG_TOGGLE_OTP,
      };
    }
  };

  const onSubmit = (val) => {
    if (step === 1) {
      sendOTPCall({
        comment: val?.reason,
        jiraId: val?.jiraId,
      });
    } else if (step === 2) {
      if (otpType === OTPRequestType.TENANT_FLAG_TOGGLE_OTP) {
        const payload = {
          flagName: toggledFlag[0],
          newFlagValue: !toggledFlag[1],
          requestId: uuid,
          otp: val,
        };
        toggleFlagPostData(JSON.stringify(payload));
      } else if (otpType === OTPRequestType.BILLING_OTP) {
        const payload = {
          otp: val,
          'request-id': uuid,
          'billing-status': (!currentBillingStatus).toString(),
        };
        toggleBillingPostData(JSON.stringify(payload, null, 2));
      }
    }
  };

  return {
    openOTP,
    setOpenOTP,
    step,
    setStep,
    uuid,
    setUuid,
    onSubmit,
    otpType,
    setOtpType,
    setToggledFlag,
    flags,
    setFlags,
    sendOTPIsLoading,
    toggleBillingIsLoading,
    getTitleAndReason,
  };
};
