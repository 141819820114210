export const adaptSilenceAlertsResponse = (response) => {
  return response?.response?.length
    ? response?.response?.map((_res) => {
        return {
          id: _res?._id,
          startsAt: _res?.startsAt,
          endsAt: _res?.endsAt,
          matchers: _res?.matchers,
          creator: _res?.creator,
        };
      })
    : [];
};
