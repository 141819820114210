import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { useGetCall, useLazyGetCallWithURL } from 'common/api/useApiCall';
import { TICKETS_INTERNAL_BASE_URL, URLS } from 'constants/URL';
import { useState, useContext, useEffect, useMemo } from 'react';
import { adaptJiraResponse } from './adaptJiraResponse';

export const useOtpModal = ({ step }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState(null);
  const [reason, setReason] = useState('');
  const [jiraId, setJiraId] = useState('');
  const [jiraDetails, setJiraDetails] = useState<any>();
  const [isJiraValidated, setIsJiraValidated] = useState(false);

  const { addNotification } = useContext(NotificationContext);

  const {
    response: jiraResponse,
    responseStatus: jiraResponseStatus,
    isLoading: isLoadingJiraDetails,
    postData: getJiraDetails,
    error: jiraError,
  } = useLazyGetCallWithURL(adaptJiraResponse);

  const { response: jiraReasons } = useGetCall(URLS.getJiraReasons);

  const onJiraSubmit = () => {
    getJiraDetails(`${TICKETS_INTERNAL_BASE_URL}/${jiraId}`);
    setIsJiraValidated(false);
  };

  useEffect(() => {
    if (jiraResponse) {
      if (jiraResponse?.id) {
        setJiraDetails(jiraResponse);
        setIsJiraValidated(true);
      } else {
        addNotification({
          severity: 'error',
          message: 'Invalid JIRA Id',
        });
      }
    }
  }, [jiraResponse]);

  const isJiraStatusDone = useMemo(() => {
    return jiraDetails?.statusCategory === 'done';
  }, [jiraDetails]);

  const isJiraCSApproved = useMemo(() => {
    return jiraDetails?.status === 'SRE+CS APPROVED';
  }, [jiraDetails]);

  useEffect(() => {
    if (jiraError) {
      addNotification({
        severity: 'error',
        message: 'Something went wrong. Please try again.',
      });
    }
  }, [jiraError]);

  const isSubmitDisabled = useMemo(() => {
    if (step === 1) {
      return reason?.trim() && isJiraValidated && isJiraCSApproved
        ? false
        : true;
    } else if (step === 2) {
      return !otp || isNaN(otp) ? true : false;
    }
    return false;
  }, [step, reason, otp, isJiraValidated]);

  return {
    showPassword,
    setShowPassword,
    otp,
    setOtp,
    reason,
    setReason,
    jiraId,
    setJiraId,
    jiraDetails,
    isJiraValidated,
    setIsJiraValidated,
    isLoadingJiraDetails,
    onJiraSubmit,
    isSubmitDisabled,
    isJiraStatusDone,
    isJiraCSApproved,
    jiraReasons,
  };
};
