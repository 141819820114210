import { TenantType } from 'customers/tenants-common-view/lib/schema/Tenant.types';
import { SharedUsersTable } from './SharedUsersTable';
import IAMIcon from 'assets/icons/IAMIcon';
import { styles } from 'assets/scss/style-templates';
import SubscriptionIcon from 'assets/icons/SubscriptionIcon';
import MyDatabasesIcon from 'assets/icons/MyDatabasesIcon';
import AvailabilityMachinesIcon from 'assets/icons/AvailabilityMachinesIcon';
import NetworkIcon from 'assets/icons/NetworkIcon';
import TicketsIcon from 'assets/icons/TicketIcon';
import { SubscriptionsTable } from './subscriptions/SubscriptionsTable';
import VirtualNetworksTable from '../../../virtual-network/VirtualNetworksTable';
import { useNavigate, useParams } from 'react-router-dom';
import { TicketsTable } from './tickets/TicketsTable';
import { AvailabilityMachinesTable } from './availabilityMachines/AvailabilityMachinesTable';
import { DbServicesTable } from 'customers/db-services-common-view/components/DBServices/DbServicesTable';
import { TabType } from 'common/tab-view/lib/schema/TabType';
import { TabView } from 'common/tab-view/lib/components/TabView';
import Events from './Events';
import TenantInfraDetails from './infraDetails/TenantInfraDetails';
import EventsIcon from 'assets/icons/EventsIcon';
import InfrastructureIcon from 'assets/icons/InfrastructureIcon';
import DBServers from './DBServers/DBServers';
import ServerAppIcon from 'assets/icons/ServerAppIcon';
import { DBEngineConfig } from './DBEngineConfig/DBEngineConfig';
import { CloudIcon, DBGovernanceIcon, IntegrationsIcon } from 'assets/icons-v2';
import { useContext } from 'react';
import { UserPermissionsContext } from 'authentication/authentication-outer/UserLoginInfo/UserLoginInfoContext';
import { PERMISSIONS } from 'Administration/IAM/Privileges/permissions';
import ActivityLog from './ActivityLog/ActivityLog';
import CloudCommands from './CloudCommands/CloudCommands';
import { TenantIntegrations } from './TenantIntegrations/TenantIntegrations';

interface TenantTabViewProps {
  tenant: TenantType;
  subscriptionId: string;
}
enum TenantTabType {
  SharedUsers = 'Users',
  Subscriptions = 'Subscriptions',
  DBServices = 'Database Services',
  AvailabilityMachines = 'Availability Machines',
  VirtualNetworks = 'Virtual Networks',
  FreshdeskTickets = 'Freshdesk Tickets',
  Cost = 'Cost',
  Events = 'Events',
  Infrastructure = 'Infrastructure',
  Alerts = 'Alerts',
  DBServers = 'DB Servers',
  DBEngineConfig = 'DB Engine Config',
  Integrations = 'Integrations',
  CloudCommands = 'Cloud Commands',
}

const RouteToTenantTabMap = {
  users: 'Users',
  subscriptions: 'Subscriptions',
  'database-services': 'Database Services',
  'availability-machines': 'Availability Machines',
  'virtual-networks': 'Virtual Networks',
  'freshdesk-tickets': 'Freshdesk Tickets',
  cost: 'Cost',
  events: 'Events',
  infrastructure: 'Infrastructure',
  alerts: 'Alerts',
  'db-servers': 'DB Servers',
  'db-engine-config': 'DB Engine Config',
  'cloud-commands': 'Cloud Commands',
  integrations: 'Integrations',
};

const TenantTabToRouteMap = {
  Users: 'users',
  Subscriptions: 'subscriptions',
  'Database Services': 'database-services',
  'Availability Machines': 'availability-machines',
  'Virtual Networks': 'virtual-networks',
  'Freshdesk Tickets': 'freshdesk-tickets',
  Cost: 'cost',
  Events: 'events',
  Infrastructure: 'infrastructure',
  Alerts: 'alerts',
  'DB Servers': 'db-servers',
  'DB Engine Config': 'db-engine-config',
  'Cloud Commands': 'cloud-commands',
  Integrations: 'integrations',
};

export const TenantTabView = (props: TenantTabViewProps): JSX.Element => {
  const navigate = useNavigate();
  const { hasPermission } = useContext(UserPermissionsContext);

  const { tenant, subscriptionId } = props;
  const params = useParams();
  const onTabClick = (event: any, newValue: string) => {
    navigate(`../${TenantTabToRouteMap[newValue]}`, {
      replace: true,
    });
  };

  const { tabId = 'subscriptions' } = params;

  const tabValue = RouteToTenantTabMap[tabId] as TenantTabType;
  const tabsProps: TabType[] = [
    {
      label: TenantTabType.Subscriptions,
      tabValue: TenantTabType.Subscriptions,
      render: () => <SubscriptionsTable tenant={tenant} />,
      icon: <SubscriptionIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: TenantTabType.DBServices,
      tabValue: TenantTabType.DBServices,
      render: () => (
        <DbServicesTable tenant={tenant} subscriptionId={subscriptionId} />
      ),
      icon: <MyDatabasesIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: TenantTabType.DBServers,
      tabValue: TenantTabType.DBServers,
      render: () => <DBServers tenant={tenant} />,
      icon: <ServerAppIcon height={16} color={styles.color.darkShade} />,
    },
    hasPermission(PERMISSIONS.TICKET_VIEW) && {
      label: TenantTabType.FreshdeskTickets,
      tabValue: TenantTabType.FreshdeskTickets,
      render: () => {
        return (
          <>
            <TicketsTable tenant={tenant} />
          </>
        );
      },
      icon: <TicketsIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: TenantTabType.SharedUsers,
      tabValue: TenantTabType.SharedUsers,
      render: () => <SharedUsersTable tenant={tenant} />,
      icon: <IAMIcon height={12} color={styles.color.darkShade} />,
    },
    {
      label: TenantTabType.AvailabilityMachines,
      tabValue: TenantTabType.AvailabilityMachines,
      render: () => {
        return <AvailabilityMachinesTable tenant={tenant} />;
      },
      icon: (
        <AvailabilityMachinesIcon height={16} color={styles.color.darkShade} />
      ),
    },
    {
      label: TenantTabType.VirtualNetworks,
      tabValue: TenantTabType.VirtualNetworks,
      render: () => {
        return (
          <>
            <VirtualNetworksTable tenant={tenant} />
          </>
        );
      },
      icon: <NetworkIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: 'Activity log',
      tabValue: TenantTabType.Events,
      render: () => <ActivityLog tenant={tenant} />,
      icon: <EventsIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: TenantTabType.Integrations,
      tabValue: TenantTabType.Integrations,
      render: () => <TenantIntegrations tenant={tenant} />,
      icon: <IntegrationsIcon height={16} color={styles.color.darkShade} />,
    },
    {
      label: TenantTabType.Infrastructure,
      tabValue: TenantTabType.Infrastructure,
      render: () => <TenantInfraDetails tenant={tenant} />,
      icon: <InfrastructureIcon height={16} color={styles.color.darkShade} />,
    },
    // {
    //   label: TenantTabType.CloudCommands,
    //   tabValue: TenantTabType.CloudCommands,
    //   render: () => <CloudCommands tenant={tenant} />,
    //   icon: <CloudIcon height={16} color={styles.color.darkShade} />,
    // },
    {
      label: TenantTabType.DBEngineConfig,
      tabValue: TenantTabType.DBEngineConfig,
      render: () => (
        <DBEngineConfig
          tenantID={tenant?._id}
          tenantDomain={tenant?.pgSource}
          tenant={tenant}
        />
      ),
      icon: <DBGovernanceIcon height={16} color={styles.color.darkShade} />,
    },
  ].filter(Boolean);

  const currentTab =
    tabsProps?.find((tab) => tab?.tabValue === tabValue) || tabsProps?.[0];

  return (
    <TabView tabs={tabsProps} onTabClick={onTabClick} currentTab={currentTab} />
  );
};
