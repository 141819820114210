import { Stack, Typography } from '@mui/material';
import { activityErrorDrawerStyles } from './styles';
import TessellTypographyWithShowMore from 'common/custom-components/lib/components/TessellTypographyWithShowMore';

interface ActivityErrorDetailsProps {
  title: string;
  details: string;
}

export const ActivityErrorDetails = (props: ActivityErrorDetailsProps) => {
  const { title, details } = props;

  return (
    <Stack sx={activityErrorDrawerStyles.individualErrorContainer}>
      <Typography sx={activityErrorDrawerStyles.title}>
        {title || '-'}
      </Typography>
      <TessellTypographyWithShowMore
        text={details || '-'}
        textStyles={activityErrorDrawerStyles.info}
        characterLimit={175}
      />
    </Stack>
  );
};
