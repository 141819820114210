import { DB_SERVICES_BASE_URL } from 'constants/URL';
import { useState } from 'react';
import { adaptDatabasesResponse } from './adaptDatabsesResponse';
import { useDataAndCount } from 'common/Hooks/useDataAndCount';
import { useParams } from 'react-router-dom';

type DatabasesProps = {
  dbServiceId?: string;
  service?: any;
};

export const useDatabases = (props: DatabasesProps) => {
  const {
     service } = props;
  const { databases } = service;
  const [selectedDatabase, setSelectedDatabase] = useState({} as any);
  const [updateMetadataOpen, setUpdateMetadataOpen] = useState(false);
  const params = useParams();
  const { databaseServiceId, tenantId, tenantDomain } = params;

  // const {
  //   count: databasesCount,
  //   pageCount: databasesPageCount,
  //   dataResponse: databases,
  //   dataIsLoading: databasesIsLoading,
  //   countIsLoading: databasesCountIsLoading,
  //   page,
  //   handlePaginationChange,
  // } = useDataAndCount({
  //   url: `${DB_SERVICES_BASE_URL}/${dbServiceId}/databases`,
  //   dataResponseAdapter: adaptDatabasesResponse,
  // });

  return {
    // page,
    // handlePaginationChange,
    databases,
    // databasesIsLoading,
    // databasesCountIsLoading,
    // databasesCount,
    // databasesPageCount,
    selectedDatabase,
    setSelectedDatabase,
    updateMetadataOpen,
    setUpdateMetadataOpen,
    tenantId,
    databaseServiceId,
    tenantDomain,
  };
};
