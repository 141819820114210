import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { contentTypeJSONHeaders, headers } from 'constants/URL';
import { useLogoutUser } from 'helpers/logout';
import { useContext, useMemo, useState } from 'react';

export const useFetch = (
  url,
  options,
  responseAdaptFn?: any,
  isContentTypeJSON?: boolean,
) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(null);
  const logout = useLogoutUser();
  const { addNotification } = useContext(NotificationContext);

  const getMessage = (status, json) => {
    const messages = {
      401: '401: Session expired or unauthorized access to the API, please login again',
      404: '404 API not found. Please connect with OPS Admin.',
      403: '403: You are not Authorized to Access this Resource Check with Admin for IAM Permissions.',
      fail: 'Unable to connect to the server or received invalid or empty response from the server',
      default: `${
        Number.isInteger(status) ? status : ''
      }: Something went wrong, please try again later`,
    };
    return (
      (json?.message &&
        `${Number.isInteger(status) ? status : ''}: ${json?.message}`) ||
      json?.details ||
      messages[status] ||
      messages.default
    );
  };

  async function postData({
    customUrl,
    body,
  }: {
    customUrl?: string;
    body?: {} | null;
  }) {
    setIsLoading(true);
    try {
      let res: any = {};
      const _url = customUrl || url;
      if (body) {
        res = await fetch(_url, {
          ...options,
          headers: isContentTypeJSON ? contentTypeJSONHeaders() : headers(),
          body,
        });
      } else {
        res = await fetch(_url, {
          ...options,
          headers: isContentTypeJSON ? contentTypeJSONHeaders() : headers(),
        });
      }
      setResponseStatus((res as any).status);
      if ((res as any)?.status === 401) {
        addNotification({
          severity: 'error',
          message: getMessage(401, null),
        });
        logout();
        return;
      }
      let json = null;
      try {
        json = await (res as any)?.json();
      } catch (error) {
        if ((res as any)?.status >= 401) {
          addNotification({
            severity: 'error',
            message: getMessage((res as any)?.status, null),
          });
        }
      }
      if ((res as any)?.status === 400 || (res as any)?.status > 401) {
        addNotification({
          severity: 'error',
          message: getMessage((res as any)?.status, json),
        });
        setIsLoading(false);
        setError(json);
        return;
      } else {
        setResponse(json);
        setError(null);
      }
    } catch (error) {
      setResponse(null);
      setError(error as any);
      if ((error as any)?.message === 'Failed to fetch') {
        addNotification({
          severity: 'error',
          message: getMessage('fail', responseStatus),
        });
      }
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
  }

  const _response = useMemo(
    () => (responseAdaptFn ? responseAdaptFn(response) : response),
    [response],
  );

  return { postData, response: _response, responseStatus, error, isLoading };
};
