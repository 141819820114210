import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { ITEMS_PER_PAGE } from 'constants/appConstants';
import { createParams } from 'helpers/createParams';
import { useEffect, useMemo, useState } from 'react';

type DataAndCountProps = {
  url: string;
  params?: any;
  dataResponseAdapter: Function;
  itemsPerPage?: number;
  initialFetch?: boolean;
};

export const useDataAndCount = (props: DataAndCountProps) => {
  const {
    url,
    params,
    dataResponseAdapter,
    itemsPerPage = ITEMS_PER_PAGE,
    initialFetch = true,
  } = props;
  const [page, setPage] = useState(1);

  const {
    response: dataResponse,
    isLoading: dataIsLoading,
    postData: fetchData,
    responseStatus: dataResponseStatus,
  } = useLazyGetCallWithURL(dataResponseAdapter);

  const {
    response: countResponse,
    isLoading: countIsLoading,
    postData: fetchCount,
    responseStatus: countResponseStatus,
  } = useLazyGetCallWithURL();

  const count = useMemo(() => {
    return countResponse?.response?.count;
  }, [countResponse]);

  const pageCount = useMemo(
    () => Math.ceil(count / itemsPerPage) || 0,
    [count],
  );

  const queryParams = useMemo(() => {
    return {
      ...params,
      'page-offset': page - 1,
      'page-size': itemsPerPage,
    };
  }, [page, JSON.stringify(params)]);

  const countParams = useMemo(() => {
    return { ...params };
  }, [JSON.stringify(params)]);

  const fetchDataAndCount = () => {
    fetchData(`${url}?${createParams(queryParams)}`);
    fetchCount(`${url}/count?${createParams(countParams)}`);
  };

  useEffect(() => {
    if (url && initialFetch) {
      fetchDataAndCount();
    }
  }, [queryParams, countParams]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  return {
    count,
    pageCount,
    dataResponse,
    dataIsLoading,
    dataResponseStatus,
    fetchDataAndCount,
    countIsLoading,
    page,
    setPage,
    handlePaginationChange,
  };
};
