import { Auth } from 'aws-amplify';
import { currentAwsConfig } from './awsConfigure';

export const mfaSetupAuth = async (
  loginInfo,
  otp,
  setUserLoginInfo,
  navigate,
  setVerifyLoading,
  addNotification,
) => {
  const awsConfig = currentAwsConfig;
  setVerifyLoading(true);
  try {
    await Auth.verifyTotpToken(loginInfo, otp)
      .then((res) => {
        Auth.setPreferredMFA(loginInfo, 'TOTP');
        if (res?.isValid()) {
          addNotification({
            severity: 'success',
            message: 'Signp successful, please login again.',
          });
        }
      })
      .then((res) => {
        setUserLoginInfo({});
        setVerifyLoading(false);
        navigate('/login');
      })
      .catch((error) => {
        console.log('Error Signing in:', new Error());
        setVerifyLoading(false);
        addNotification({
          severity: 'error',
          message: error?.message || 'Something went wrong',
        });
      });
  } catch (error) {
    console.log('Error Signing in', error);
    setUserLoginInfo({});
    return error;
  }
};
