import Cache from 'common/cache/lib/utils/Cache';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useFilters = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const updateFilters = useCallback(
    (filters: { [key: string]: string }) => {
      const searchParams = new URLSearchParams(location.search);
      Object.entries(filters).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
      try {
        const cachedFilters = JSON.parse(Cache.get('filters'));
        cachedFilters[location.pathname] = filters;
        Cache.set('filters', JSON.stringify(cachedFilters));
      } catch (error) {
        const cachedFilters =
          (Cache.get('filters') && Cache.get('filters') !== 'null') || {};
        cachedFilters[location.pathname] = filters;
        Cache.set('filters', JSON.stringify(cachedFilters));
      }
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    },
    [navigate, location],
  );

  const getFilters = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const filters: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      filters[key] = value;
    });
    try {
      const cachedFilters = JSON.parse(Cache.get('filters'));
      if (!cachedFilters || !cachedFilters[location.pathname]) {
        cachedFilters[location.pathname] = filters;
        Cache.set('filters', JSON.stringify(cachedFilters));
      }
      return cachedFilters[location.pathname] || filters;
    } catch (error) {
      console.log('Error in updating filters', error);
    }
    return filters;
  }, [location.search]);

  return { updateFilters, getFilters };
};
