import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { DatePicker } from '@mui/x-date-pickers';
import {
  ArrowBack,
  Clear,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { styles } from 'assets/scss/style-templates';
import { LoadingButton } from '@mui/lab';
import OtpModal from 'common/Modals/OtpModal';
import TessellAutocomplete from 'common/custom-components/lib/components/TessellAutocomplete';
import { OTP_TYPE } from 'constants/otpType';
import { DedicatedCloudRow } from 'customers/tenants-detailed-view/lib/components/onboarding/DedicatedCloudRow';
import { onboardingStyles } from './styles';
import {
  CustomerType,
  DeploymentOptions,
  DeploymentOptionsEnum,
  SupportPlans,
  enabledCloudsArray,
  tenantPhasesOptions,
} from './constants';
import { useOnboarding } from './useOnboarding';
import { clearEmptyValuesFromObject } from 'common/UpdateMetadata/utils';
import { TessellMultiInputWithAdd } from 'common/custom-components/lib/components/TessellMultiInputWithAdd';

export default function OnboardTenant() {
  const {
    state,
    updateForm,
    showPassword,
    setShowPassword,
    updateFormValue,
    addBillingConfig,
    isPe,
    setState,
    deploymentNames,
    namespace,
    setNameSpace,
    availableNamespaces,
    nameSpacesIsLoading,
    showNameSpace,
    updateDeploymentConfig,
    handleChangeEnabledClouds,
    setAddBillingConfig,
    billingConfig,
    toggleBooleanBillingConfig,
    setBillingConfig,
    discount,
    navigate,
    setDiscount,
    addDedicatedCloudAccounts,
    dedicatedCloudAccountsArray,
    submitting,
    onboardRes,
    disableSubmit,
    openOTP,
    setOpenOTP,
    step,
    setAddDedicatedCloudAccounts,
    setDedicatedCloudAccountsArray,
    defaultDedicatedCloudAccount,
    sendOTP,
    uuid,
    sendOTPIsLoading,
    deleteDedicatedCloudObject,
    updateState,
    disableAddingCustomerEmail,
    users,
    handlePOCOwnersChange,
    handleTenantCustomerEmailContactsChange,
  } = useOnboarding();

  return (
    <>
      <Stack p={4}>
        <Stack direction={'row'} sx={onboardingStyles.titleContainer}>
          <Stack direction={'row'} spacing={2}>
            <Button
              color="primary"
              variant="text"
              onClick={() => {
                navigate('/tenants');
              }}
              disabled={submitting}
            >
              <ArrowBack fontSize="large" />
            </Button>
            <Typography variant="h4" sx={onboardingStyles.sectionTitles}>
              Onboard a Tenant
            </Typography>
          </Stack>

          <Stack sx={onboardingStyles.actionButtonContainer}>
            <LoadingButton
              onClick={() => {
                setOpenOTP(true);
              }}
              color="primary"
              loading={onboardRes?.isLoading}
              variant="contained"
              sx={{
                width: 100,
                ml: 1,
              }}
              disabled={disableSubmit()}
            >
              Submit
            </LoadingButton>
          </Stack>
        </Stack>
        <Stack spacing={3}>
          <Stack spacing={2} direction={'row'}>
            <Stack spacing={3} width="33%">
              <Typography variant="h5" sx={onboardingStyles.sectionTitles}>
                Customer details
              </Typography>
              <Stack spacing={3} sx={onboardingStyles.formBox}>
                <TessellInput
                  markAsRequired
                  label={'First Name'}
                  variant="outlined"
                  size="small"
                  name="firstName"
                  placeholder="First Name"
                  value={state?.firstName}
                  onChange={updateForm}
                />
                <TessellInput
                  markAsRequired
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  name="lastName"
                  placeholder="Last Name"
                  value={state?.lastName}
                  onChange={updateForm}
                />

                <TessellInput
                  markAsRequired
                  label="Email Address"
                  variant="outlined"
                  size="small"
                  name="emailId"
                  fullWidth
                  placeholder="Email Address"
                  value={state?.emailId}
                  onChange={updateForm}
                />

                <TessellInput
                  markAsRequired
                  label="Password"
                  variant="outlined"
                  size="small"
                  name="password"
                  placeholder="Password"
                  value={state?.password}
                  onChange={updateForm}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    className: 'form-input',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          {showPassword ? (
                            <Visibility
                              sx={{ color: styles?.color?.darkShade }}
                            />
                          ) : (
                            <VisibilityOff
                              sx={{ color: styles?.color?.darkShade }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TessellInput
                  markAsRequired
                  label="Company"
                  variant="outlined"
                  size="small"
                  name="company"
                  fullWidth
                  placeholder="Company"
                  value={state?.company}
                  onChange={(e) => updateState('company', e?.target?.value)}
                />
                <TessellInput
                  label="Country"
                  variant="outlined"
                  size="small"
                  name="country"
                  fullWidth
                  placeholder="Country"
                  value={state?.country}
                  onChange={(e) => updateState('country', e?.target?.value)}
                />
                <TessellInput
                  markAsRequired
                  label="Job Title"
                  variant="outlined"
                  size="small"
                  name="jobTitle"
                  fullWidth
                  placeholder="Job Title"
                  value={state?.jobTitle}
                  onChange={(e) => updateState('jobTitle', e?.target?.value)}
                />
                <TessellInput
                  label="Customer Type"
                  variant="outlined"
                  size="small"
                  name="customerType"
                  select
                  value={state?.customerType}
                  onChange={(e) =>
                    updateState('customerType', e?.target?.value)
                  }
                  options={CustomerType.map((v) => (
                    <MenuItem key={v.value} value={v.value}>
                      {v.name}
                    </MenuItem>
                  ))}
                />
                <FormControlLabel
                  label="Skip sending verification email"
                  control={
                    <Switch
                      color="primary"
                      checked={state.skipVerificationEmail}
                      onChange={() =>
                        updateFormValue(
                          'skipVerificationEmail',
                          !state.skipVerificationEmail,
                        )
                      }
                    />
                  }
                />
              </Stack>
            </Stack>
            <Stack spacing={3} width="33%">
              <Typography variant="h5" sx={onboardingStyles.sectionTitles}>
                Deployment details
              </Typography>
              <Stack spacing={3} sx={onboardingStyles.formBox}>
                <Stack spacing={2} width="100%">
                  <TessellInput
                    label="Deployment Option"
                    variant="outlined"
                    size="small"
                    name="deploymentPlan"
                    select
                    value={state?.deploymentConfig?.deploymentPlan}
                    onChange={(e) => updateDeploymentConfig(e)}
                    options={DeploymentOptions.map((v) => (
                      <MenuItem key={v.value} value={v.value}>
                        {v.name}
                      </MenuItem>
                    ))}
                  />
                  {!isPe && (
                    <TessellInput
                      markAsRequired
                      label={isPe ? '' : 'Domain'}
                      variant="outlined"
                      size="small"
                      name="domain"
                      fullWidth
                      placeholder="Custom domain"
                      value={state?.domain}
                      onChange={updateForm}
                    />
                  )}
                  <TessellAutocomplete
                    markAsRequired
                    label="Deployment Name"
                    variant="outlined"
                    size="small"
                    name="deploymentName"
                    value={state?.deploymentConfig?.deploymentName}
                    onChange={(value) => {
                      setState((prev) => ({
                        ...prev,
                        deploymentConfig: {
                          ...prev.deploymentConfig,
                          deploymentName: value,
                        },
                      }));
                    }}
                    options={deploymentNames?.map((item) => {
                      return {
                        label: item?.displayName || item?.name,
                        value: item?.name,
                      };
                    })}
                  />
                  <FormControlLabel
                    label="Shared Control Plane"
                    control={
                      <Switch
                        color="primary"
                        defaultChecked={true}
                        checked={
                          isPe || state?.deploymentConfig?.isSharedControlPlane
                        }
                        onChange={() =>
                          updateDeploymentConfig(
                            'isSharedControlPlane',
                            !state?.deploymentConfig?.isSharedControlPlane,
                          )
                        }
                      />
                    }
                  />
                  {showNameSpace && (
                    <Stack
                      spacing={2}
                      direction={'row'}
                      width={'100%'}
                      alignItems={'end'}
                      justifyContent={'space-between'}
                    >
                      <TessellAutocomplete
                        width="100%"
                        label="Available Namespaces"
                        variant="outlined"
                        size="small"
                        name="availableNamespaces"
                        value={namespace}
                        onChange={(value) => {
                          setNameSpace(value);
                        }}
                        options={availableNamespaces?.map((item) => {
                          return { label: item, value: item };
                        })}
                      />
                      {nameSpacesIsLoading && <CircularProgress />}
                    </Stack>
                  )}
                  <FormControl>
                    <InputLabel sx={{ mt: 2 }}>Enabled Clouds</InputLabel>
                    <Stack mt={2}>
                      <Select
                        multiple
                        // @ts-ignore
                        value={state?.enabledClouds}
                        onChange={handleChangeEnabledClouds}
                        input={<OutlinedInput label="Enabled Clouds" />}
                      >
                        {enabledCloudsArray.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  </FormControl>
                  <TessellInput
                    label="Tenant Phase"
                    variant="outlined"
                    size="small"
                    name="tenantPhase"
                    select
                    value={state?.tenantPhase}
                    onChange={(e) =>
                      updateState('tenantPhase', e?.target?.value)
                    }
                    options={tenantPhasesOptions.map((v) => (
                      <MenuItem key={v.value} value={v.value}>
                        {v.name}
                      </MenuItem>
                    ))}
                    InputProps={{
                      className: 'form-input',
                      endAdornment: state?.tenantPhase?.length ? (
                        <InputAdornment position="end">
                          <IconButton
                            sx={{ marginRight: '5px' }}
                            onClick={() => updateState('tenantPhase', '')}
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <></>
                      ),
                    }}
                  />

                  <TessellAutocomplete
                    label="Tenant Owners"
                    variant="outlined"
                    size="small"
                    multiple
                    value={state?.opsMetadata?.tenantPocOwnerEmails}
                    onChange={(updatedArray) => {
                      handlePOCOwnersChange(updatedArray);
                    }}
                    options={users?.map((_user) => {
                      return {
                        label: _user.email,
                        value: _user.email,
                      };
                    })}
                  />

                  <TessellMultiInputWithAdd
                    onChange={(updatedArray) => {
                      handleTenantCustomerEmailContactsChange(updatedArray);
                    }}
                    stringArray={
                      state?.opsMetadata?.tenantCustomerEmailContacts
                    }
                    label="Tenant Customer Email Contacts"
                    placeHolder="Add Customer Email"
                    disableAdding={disableAddingCustomerEmail}
                  />

                  <FormControlLabel
                    label="Add dedicated cloud accounts"
                    control={
                      <Switch
                        disabled={
                          state?.deploymentConfig?.deploymentPlan !==
                          DeploymentOptionsEnum.VPT
                        }
                        color="primary"
                        checked={
                          addDedicatedCloudAccounts &&
                          state.deploymentConfig.deploymentPlan ===
                            DeploymentOptionsEnum.VPT
                        }
                        onChange={() => {
                          setAddDedicatedCloudAccounts((prev) => !prev);
                        }}
                      />
                    }
                  />
                  {addDedicatedCloudAccounts &&
                    state.deploymentConfig.deploymentPlan ===
                      DeploymentOptionsEnum.VPT && (
                      <Stack sx={onboardingStyles.toggleActionContainer}>
                        <Typography variant="h6">
                          Dedicated cloud accounts
                        </Typography>
                        <Stack>
                          {dedicatedCloudAccountsArray?.map(
                            (_dedicatedCloud, index) => {
                              return (
                                <DedicatedCloudRow
                                  key={index}
                                  dedicatedCloudObject={_dedicatedCloud}
                                  handleOnChange={(key, value) => {
                                    setDedicatedCloudAccountsArray((prev) =>
                                      prev?.map((obj, i) => {
                                        return i === index
                                          ? { ...obj, [key]: value }
                                          : obj;
                                      }),
                                    );
                                  }}
                                  handleActionClick={() => {
                                    return index === 0
                                      ? setDedicatedCloudAccountsArray(
                                          (prev) => [
                                            ...prev,
                                            defaultDedicatedCloudAccount,
                                          ],
                                        )
                                      : deleteDedicatedCloudObject(index);
                                  }}
                                  indexForButtonText={index}
                                />
                              );
                            },
                          )}
                        </Stack>
                      </Stack>
                    )}
                </Stack>
              </Stack>
            </Stack>
            <Stack spacing={3} width="33%">
              <Typography variant="h5" sx={onboardingStyles.sectionTitles}>
                Billing details
              </Typography>
              <Stack spacing={3} sx={onboardingStyles.formBox}>
                <Stack width="100%" spacing={2}>
                  <TessellInput
                    label="Credits"
                    variant="outlined"
                    size="small"
                    name="initialCredits"
                    onChange={updateForm}
                    placeholder="Credits in USD"
                    value={state?.initialCredits}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    inputProps={{ min: 0, decimalScale: 2 }}
                  />
                  <TessellInput
                    label="Support Plan"
                    variant="outlined"
                    size="small"
                    name="supportPlan"
                    select
                    value={state?.supportPlan}
                    onChange={updateForm}
                    options={SupportPlans.map((v) => (
                      <MenuItem key={v.value} value={v.value}>
                        {v.name}
                      </MenuItem>
                    ))}
                  />
                </Stack>
                <Stack pt={1} spacing={2}>
                  <FormControlLabel
                    label="Add Billing config"
                    control={
                      <Switch
                        color="primary"
                        checked={addBillingConfig}
                        onChange={() => setAddBillingConfig((prev) => !prev)}
                      />
                    }
                  />
                  {addBillingConfig && (
                    <Stack sx={onboardingStyles.toggleActionContainer}>
                      <Typography variant="h6">Billing Config</Typography>
                      <Stack>
                        <Stack>
                          <FormControlLabel
                            label="Skip invoice finalize"
                            control={
                              <Switch
                                color="primary"
                                checked={billingConfig?.skipInvoiceFinalize}
                                onChange={() =>
                                  toggleBooleanBillingConfig(
                                    'skipInvoiceFinalize',
                                  )
                                }
                              />
                            }
                          />
                          <FormControlLabel
                            label="Disabled"
                            control={
                              <Switch
                                color="primary"
                                checked={billingConfig?.disabled}
                                onChange={() =>
                                  toggleBooleanBillingConfig('disabled')
                                }
                              />
                            }
                          />
                          <FormControlLabel
                            label="Hide billing credits unit"
                            control={
                              <Switch
                                color="primary"
                                checked={billingConfig?.hideBillingCreditsUnit}
                                onChange={() =>
                                  toggleBooleanBillingConfig(
                                    'hideBillingCreditsUnit',
                                  )
                                }
                              />
                            }
                          />
                        </Stack>
                        <Stack>
                          <TessellInput
                            label="Fixed bill amount"
                            variant="outlined"
                            size="small"
                            name="fixedBillAmount"
                            fullWidth
                            placeholder="Fixed bill amount"
                            value={billingConfig?.fixedBillAmount}
                            onChange={(e) =>
                              setBillingConfig((prev) => ({
                                ...prev,
                                fixedBillAmount: e?.target?.value,
                              }))
                            }
                          />
                          <TessellInput
                            label="Add Discount"
                            variant="outlined"
                            size="small"
                            name="discount"
                            fullWidth
                            placeholder="Add discount"
                            value={discount?.percentage}
                            onChange={(e) =>
                              setDiscount((prev) => ({
                                ...prev,
                                percentage: e?.target?.value,
                              }))
                            }
                          />
                        </Stack>

                        <Stack
                          justifyContent="center"
                          direction="row"
                          mt={3}
                          spacing={2}
                        >
                          <DatePicker
                            label="Start"
                            renderInput={(params) => <TextField {...params} />}
                            value={discount?.effectiveStartDate}
                            onChange={(e) =>
                              setDiscount((prev) => ({
                                ...prev,
                                effectiveStartDate: e,
                              }))
                            }
                            PopperProps={{
                              placement: 'left',
                            }}
                          />

                          <DatePicker
                            label="End"
                            renderInput={(params) => {
                              return (
                                <TextField {...params} ref={params?.inputRef} />
                              );
                            }}
                            value={discount?.endDate}
                            onChange={(e) =>
                              setDiscount((prev) => ({
                                ...prev,
                                endDate: e,
                              }))
                            }
                            PopperProps={{
                              placement: 'right',
                            }}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <OtpModal
        open={openOTP}
        setOpen={setOpenOTP}
        onSubmit={(val) => {
          if (step === 1) {
            const { reason, jiraId } = val;
            const payload = {
              comment: reason,
              jiraId: jiraId,
              email: encodeURIComponent(state?.emailId),
              ...(!(
                state?.deploymentConfig?.deploymentPlan ===
                DeploymentOptionsEnum.Professional
              ) && {
                domain: state?.domain,
              }),
            };
            sendOTP(JSON.stringify(payload, null));
          } else {
            const payload = {
              otp: val,
              'request-id': uuid,
              onboardPayload: clearEmptyValuesFromObject(state),
            };
            onboardRes.postData(JSON.stringify(payload, null, 2));
          }
        }}
        isSubmitting={step === 1 ? sendOTPIsLoading : onboardRes?.isLoading}
        otpUser="Ops admin"
        step={step}
        reasonSubmitBtnText="Submit"
        reasonHeading="Enter Reason for Onboarding (with JIRA link)"
        otpType={OTP_TYPE.ONBOARD_TENANT}
      />
    </>
  );
}
