import StatusLabel from 'common/custom-components/lib/components/StatusLabel';
import { useTenantIntegrations } from './useTenantIntegrations';
import {
  StatusBackgroundColor,
  StatusTextColor,
} from 'constants/statusConfigs';
import { Stack, Typography } from '@mui/material';
import { render } from 'react-dom';
import DataGrid from 'common/mui-data-grid/lib/components/DataGrid';

export const TenantIntegrations = (props): JSX.Element => {
  const { tenant } = props;
  const { _id: tenantId, pgSource: tenantDomain } = tenant;
  const { data, isLoading } = useTenantIntegrations({ tenantId, tenantDomain });
  const dbColumns = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      renderCell: (params) => {
        const { row: data } = params;
        const { name, integrationType } = data;
        return (
          <Stack direction="column" alignItems="flex-start">
            <Typography variant="h6">{name}</Typography>
            <Typography variant="caption">{integrationType}</Typography>
          </Stack>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params) => {
        const { row: data } = params;
        const { status } = data;
        return (
          <Stack direction="row" alignItems="flex-start">
            <StatusLabel
              backgroundColor={StatusBackgroundColor[status]}
              textColor={StatusTextColor[status]}
              text={status || 'NA'}
            />
          </Stack>
        );
      },
      width: 150,
    },
  ];

  return (
    <Stack sx={{ height: '50vh' }}>
      <DataGrid
        columns={dbColumns}
        rows={data}
        loading={isLoading}
        styleOverrides={{ hideShadow: true }}
        clickable={false}
        rowHoverId="id"
        getRowId={(row) => `${row.id}`}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
      />
    </Stack>
  );
};
