import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import { useDBEvents } from './useDBEvents';
import { eventsStyles } from './styles';
import { Timeline, timelineItemClasses } from '@mui/lab';
import { SingleEvent } from './components/SingleEvent';
import { Stack } from '@mui/material';
import { SingleEventType } from './constants';
import EventsSingleTimelineTile from './components/EventsSingleTimelineTile';
import LoadingOverlay from 'common/custom-components/lib/components/LoadingOverlay';

type DBEventsProps = {
  service?: DatabaseServiceType;
};

const DBEvents = (props: DBEventsProps) => {
  const { service } = props;

  const { events, eventsIsLoading } = useDBEvents();

  return (
    <>
      <LoadingOverlay isLoading={eventsIsLoading}>
        {events?.length === 1 ? (
          <SingleEvent event={events?.[0]} />
        ) : (
          <Stack sx={eventsStyles.timelineContainer}>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
                maxWidth: '600px',
              }}
            >
              {events.map((item: SingleEventType, idx) => (
                <EventsSingleTimelineTile
                  label={item?.label}
                  userComment={item?.userComment}
                  timestamp={item?.timestamp}
                  eventType={item?.eventType}
                  isLast={idx === events.length - 1}
                  contextInfo={item?.contextInfo}
                />
              ))}
            </Timeline>
          </Stack>
        )}
      </LoadingOverlay>
    </>
  );
};

export default DBEvents;
