import {
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import TessellModal from 'common/custom-components/lib/components/TessellModal';
import { styles } from 'assets/scss/style-templates';
import { useStyles } from '../utils';
import TessellTextarea from '../custom-components/lib/components/TessellTextarea';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import TessellInput from 'common/custom-components/lib/components/TessellInput';
import { ShowLabelValue } from 'common/custom-components/lib/components/ShowLabelValue';
import { useOtpModal } from './useOtpModal';
import { useMemo } from 'react';

type OtpModalProps = {
  open: boolean;
  setOpen: any;
  onSubmit: any;
  title?: string;
  subTitle?: string;
  isSubmitting: boolean;
  otpErrorMsg?: string;
  setOtpErrorMsg?: any;
  reasonHeading?: string;
  reasonSubmitBtnText?: string;
  step: number;
  otpUser: string;
  children?: any;
  stepZeroComponent?: any;
  setStep?: any;
  actionButtons?: any;
  otpFormat?: 'string' | 'number';
  otpType: string;
};

const OtpModal = (props: OtpModalProps) => {
  const classes = useStyles();

  const {
    open,
    setOpen,
    actionButtons,
    setStep,
    children,
    onSubmit,
    isSubmitting,
    otpErrorMsg,
    setOtpErrorMsg,
    reasonHeading,
    title,
    subTitle,
    reasonSubmitBtnText,
    step = 1,
    otpUser = 'user',
    stepZeroComponent,
    otpFormat = 'number',
    otpType,
  } = props;

  const {
    showPassword,
    setShowPassword,
    otp,
    setOtp,
    reason,
    setReason,
    jiraId,
    setJiraId,
    jiraDetails,
    isJiraValidated,
    setIsJiraValidated,
    isLoadingJiraDetails,
    onJiraSubmit,
    isSubmitDisabled,
    isJiraCSApproved,
    jiraReasons,
  } = useOtpModal({ step });

  const DialogTitle = () =>
    title?.length ? (
      <Stack>
        {title}
        {subTitle && <Typography>{subTitle}</Typography>}
      </Stack>
    ) : (
      <>
        {step === 1 && !title?.length
          ? reasonHeading || 'Enter Reason (with JIRA link)'
          : 'Enter OTP'}
      </>
    );

  const showJiraStatusCloseError = useMemo(() => {
    return isJiraValidated && jiraDetails && !isJiraCSApproved;
  }, [isJiraValidated, jiraDetails, isJiraCSApproved]);

  const DialogContent = (step) => {
    switch (step) {
      case 0:
        return stepZeroComponent;
      case 1:
        return (
          <>
            <TessellInput
              label="JIRA Id"
              variant="outlined"
              size="small"
              placeholder="Enter JIRA Id"
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  onJiraSubmit();
                  ev.preventDefault();
                }
              }}
              onChange={(e) => {
                setJiraId(e.target.value);
                setIsJiraValidated(false);
              }}
              sx={{
                mt: '10px',
              }}
              disabled={isLoadingJiraDetails}
            />
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Stack>
                {showJiraStatusCloseError ? (
                  <Typography color={styles.color.red}>
                    This JIRA is not SRE + CS approved. Please enter another JIRA ticket.
                  </Typography>
                ) : (
                  <></>
                )}
              </Stack>

              <LoadingButton
                variant="text"
                onClick={onJiraSubmit}
                disabled={!jiraId?.trim() || isJiraValidated}
                loading={isLoadingJiraDetails}
              >
                Validate JIRA
              </LoadingButton>
            </Stack>
            {isJiraValidated && jiraDetails ? (
              <Stack mb={2} mt={2}>
                <Typography>JIRA Details</Typography>
                <ShowLabelValue
                  label="Subject"
                  value={jiraDetails?.summary || ''}
                />
                {jiraDetails?.status && (
                  <ShowLabelValue
                    label="Status"
                    value={jiraDetails?.status || ''}
                  />
                )}
              </Stack>
            ) : (
              <></>
            )}
            <TessellInput
              label="Reason"
              variant="outlined"
              size="small"
              name="reason"
              select
              onChange={(e) => {
                setReason(e.target.value);
              }}
              options={(jiraReasons?.[otpType] || [])?.map((v) => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            />
          </>
        );
      case 2:
        return (
          <>
            <div className="mt-3">
              Otp has been sent to {otpUser} successfully.
            </div>
            <TessellInput
              variant="outlined"
              size="small"
              placeholder="Enter OTP"
              autofill="false"
              autoComplete="new-password"
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  onSubmit();
                  ev.preventDefault();
                }
              }}
              onChange={(e) => {
                setOtpErrorMsg?.('');
                setOtp(e.target.value);
              }}
              sx={{
                mb: '5px',
                mt: '5px',
              }}
              type={showPassword ? 'number' : 'password'}
              InputProps={{
                className: 'form-input',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? (
                        <Visibility sx={{ color: styles?.color?.darkShade }} />
                      ) : (
                        <VisibilityOff
                          sx={{ color: styles?.color?.darkShade }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {children}

            {otpErrorMsg && (
              <>
                <Stack>
                  <Typography color={styles.color.red}>
                    {otpErrorMsg}
                  </Typography>
                </Stack>
              </>
            )}
          </>
        );
    }
  };

  const onClose = () => {
    setOpen(false);
    setOtp('');
    setOtpErrorMsg?.('');
    setStep?.(1);
    setReason('');
    setIsJiraValidated(false);
  };

  const buttonText = (step) => {
    if (step === 0) {
      return 'Modify';
    }
    if (step === 1) {
      return reasonSubmitBtnText || 'Submit';
    }
    if (step === 2) {
      return 'Submit';
    }
  };

  const DialogActions = () => (
    <Stack width="100%" direction="row" justifyContent="flex-start" mt={2}>
      <Stack spacing={1} direction="row" justifyContent="flex-end" width="100%">
        <Button
          onClick={onClose}
          variant="outlined"
          size="small"
          sx={classes.roundedBtn}
        >
          Cancel
        </Button>
        {actionButtons}
        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => {
            if (step === 1) {
              onSubmit({
                reason,
                jiraId: jiraDetails?.id,
                jiraSummary: jiraDetails?.summary || '',
              });
            } else if (step === 2) {
              onSubmit(otpFormat === 'string' ? otp : parseInt(otp));
            } else {
              onSubmit();
            }
          }}
          loading={isSubmitting}
          disabled={isSubmitDisabled}
        >
          {buttonText(step)}
        </LoadingButton>
      </Stack>
    </Stack>
  );

  const renderFunctions = {
    title: DialogTitle,
    content: () => DialogContent(step),
    actions: DialogActions,
  };

  const customStyles = {
    title: {},
    content: classes.deleteModalContent,
    actions: {},
  };

  return (
    <TessellModal
      customStyles={customStyles}
      renderFunctions={renderFunctions}
      maxWidth="sm"
      open={open}
      onClose={onClose}
    />
  );
};

export default OtpModal;
