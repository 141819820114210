import { tenantPhasesOptions } from 'customers/tenants-detailed-view/lib/components/onboarding/constants';

export const UpdateMetaDataLabels = {
  tenantPocOwnerEmails: 'Tenant Owners',
  tenantCustomerEmailContacts: 'Tenant Customer Email Contacts',
};

export const nameLabelTypeMap = {
  numOfInstances: { label: 'Number of instances', type: 'number' },
  status: { label: 'Status' },
  'contextInfo.subStatus': { label: 'SubStatus (context info)' },
  'contextInfo.description': { label: 'Description (context info)' },
  contextInfo: { label: 'Context Info' },
  'engineConfiguration.userView.oracleConfig.enableArchiveMode': {
    label: 'Enable Archive Mode',
    type: 'boolean',
  },
  ownerId: { label: 'Owner Id' },
  connectivityInfo: { label: 'Connectivity Info' },
  tessellGenieInfo: { label: 'Tessell Genie Info' },
  'provisionInfo.serviceConnectivity': {
    label: 'Service Connectivity (provision info)',
  },
  updatesInProgressInfo: { label: 'Updates In Progress Info' },
  type: { label: 'Type' },
  role: { label: 'Role' },
  pluginStatus: { label: 'Plugin Status' },
  connectionInfo: { label: 'Connection Info' },
  cloudStatus: { label: 'Cloud Status' },
  machineFqdnInfo: { label: 'Machine Fqdn Info' },
  ipAddressInfo: { label: 'IP Address Info' },
  name: { label: 'Name' },
  databaseStats: { label: 'Database Stats' },
  sizeInfo: { label: 'Size Info' },
  cloudId: { label: 'Cloud Id' },
  internalStatus: { label: 'Internal Status' },
  isDefault: { label: 'Is Default', type: 'boolean' },
  isTessellCreated: { label: 'Is Tessell Created', type: 'boolean' },
  isPrivate: { label: 'Is Private', type: 'boolean' },
  onlyForPrivateAccess: { label: 'Only For Private Access', type: 'boolean' },
  eligibleForPrivate: { label: 'Eligible For Private', type: 'boolean' },
  isOld: { label: 'Is Old', type: 'boolean' },
  tenantPhase: {
    label: 'Tenant Phase',
    type: 'select',
    options: tenantPhasesOptions,
  },
  'metadata.opsMetadata.tenantPocOwnerEmails': {
    label: UpdateMetaDataLabels.tenantPocOwnerEmails,
    type: 'multiSelect',
  },
  'metadata.opsMetadata.tenantCustomerEmailContacts': {
    label: UpdateMetaDataLabels.tenantCustomerEmailContacts,
    type: 'multiInputWithAdd',
    placeHolder: 'Add customer email',
  },
  vmAccessForCustomer: {
    label: 'Enable VM Access for Customer',
    type: 'boolean',
    description: 'Check if Genie for customer feature is enabled',
  },
  approvalForGenieExternal: {
    label: 'Tessell approval needed for Customer-requested genie',
    type: 'boolean',
    description:
      'If approval from Tessell is required when Genie is requested by external customer',
  },
  approvalForGenieInternal: {
    label: 'Customer approval needed for Tessell-requested genie',
    type: 'boolean',
    description:
      'If approval from Customer is required when Genie is requested by internal support team',
  },
  installFrp: {
    label: 'Install FRP client',
    type: 'boolean',
    description:
      'If FRP client software can be installed or updated on the provisioned VMs',
  },
};

export const ROWS_FOR_OBJECT_COUNT = 4;
export const DEFAULT_ROWS_COUNT = 1;
export const DEFAULT_PAYLOAD = {
  oldData: {} as any,
  newData: {} as any,
  'request-id': '',
  otp: '',
};

export const UPDATE_METADATA_ENTITY_TYPE = {
  TENANT_GENIE_CONFIG: 'tenantGenieConfig',
};

export const DialogTitleString = {
  [UPDATE_METADATA_ENTITY_TYPE.TENANT_GENIE_CONFIG]: 'Genie Config',
};

// Example if opsMetadata comes null and we want to update tenantCustomerEmailContacts
export const handleNullCasesInUpdateMetadataChain = {
  opsMetadata: {
    tenantCustomerEmailContacts: [],
    tenantPocOwnerEmails: [],
  },
};
