import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { useEffect } from 'react';
import { adaptEventsResponse } from './adaptEventsResponse';
import { useParams } from 'react-router-dom';

export const useDBEvents = () => {
  const params = useParams();

  const { databaseServiceId: serviceId, tenantDomain } = params;

  const {
    response: events,
    isLoading: eventsIsLoading,
    postData: getEvents,
  } = useLazyGetCallWithURL(adaptEventsResponse);

  useEffect(() => {
    if (serviceId && tenantDomain)
      getEvents(
        `${URLS.getServiceEvents}${serviceId}/events?tenant-domain=${tenantDomain}`,
      );
  }, [serviceId, tenantDomain]);

  return {
    events,
    eventsIsLoading,
  };
};
