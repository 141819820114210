export const adaptActivityLog = (data) => {
  return (
    data?.response?.map((activity) => ({
      id: activity.activityId,
      entityName: activity.entityName,
      app: activity.app,
      description: activity.description,
      actionType: activity.actionType,
      status: activity.status,
      entityType: activity.entityType,
      initiatedBy: activity.initiatedBy,
      owner: activity.owner,
      initiatedAt: activity.initiatedAt,
      completedAt: activity.completedAt,
      exception: activity.exceptions,
    })) || []
  );
};

type ProcessedActivity = {
  id: string;
  entityName: string;
  app: string;
  description: string;
  actionType: string;
  status: string;
  entityType: string;
  initiatedBy: string;
  owner: string;
  initiatedAt: string;
  completedAt: string;
  exception: string;
  hierarchy: number[];
  childActivities?: ProcessedActivity[];
};

export function adaptActivityLogWithChild(activityLog) {
  const log = activityLog?.response || activityLog;
  const flattenedList = [];

  function processActivity(activity, hierarchy) {
    const processedActivity: ProcessedActivity = {
      id: activity.activityId,
      entityName: activity.entityName,
      app: activity.app,
      description: activity.description,
      actionType: activity.actionType,
      status: activity.status,
      entityType: activity.entityType,
      initiatedBy: activity.initiatedBy,
      owner: activity.owner,
      initiatedAt: activity.initiatedAt,
      completedAt: activity.completedAt,
      exception: activity.exceptions,
      hierarchy: hierarchy,
    };

    flattenedList.push(processedActivity);

    if (activity?.childActivities && activity?.childActivities?.length > 0) {
      activity.childActivities.forEach((childActivity, index) =>
        processActivity(childActivity, [...hierarchy, index + 1]),
      );
    }
  }

  Array.isArray(log) &&
    log?.forEach((activity, index) => processActivity(activity, [index + 1]));

  return flattenedList;
}
// Example usage:
const activityLog = [
  {
    activityId: '1',
    entityName: 'Entity 1',
    app: 'App 1',
    description: 'Description 1',
    actionType: 'Action 1',
    status: 'Status 1',
    entityType: 'Type 1',
    initiatedBy: 'User 1',
    owner: 'Owner 1',
    initiatedAt: '2023-01-01T00:00:00Z',
    completedAt: '2023-01-02T00:00:00Z',
    exceptions: 'None',
    childActivities: [
      {
        activityId: '1.1',
        entityName: 'Entity 1.1',
        app: 'App 1.1',
        description: 'Description 1.1',
        actionType: 'Action 1.1',
        status: 'Status 1.1',
        entityType: 'Type 1.1',
        initiatedBy: 'User 1.1',
        owner: 'Owner 1.1',
        initiatedAt: '2023-01-01T01:00:00Z',
        completedAt: '2023-01-02T01:00:00Z',
        exceptions: 'None',
      },
    ],
  },
];

