import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _includes from 'lodash/includes';

import { ServiceSummary } from './ServiceSummary/ServiceSummary';
import { ServiceHeader } from './ServiceHeader';
import BackButton from 'common/custom-components/lib/components/BackButton';
import { ServiceTabView } from './ServiceTabView';
import { DB_SERVICES_BASE_URL, URLS } from 'constants/URL';
import { useGetCall } from 'common/api/useApiCall';
import LoadingOverlay from 'common/custom-components/lib/components/LoadingOverlay';

export default function ServiceDetailedView(props) {
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const id = params?.databaseServiceId;
  const tenantId = params?.tenantId;
  const tenantDomain = params?.tenantDomain;
  const [service, setService] = useState<any>();

  const {
    response: responseByServiceId,
    isLoading: serviceIsLoading,
    postData: fetchDBServiceId,
    // } = useGetCall(`${DB_SERVICES_BASE_URL}/${id}`);
  } = useGetCall(
    `${URLS.updateDbServicesMetadata}tenant-id=${tenantId}&tenant-domain=${tenantDomain}&dbservice-id=${id}`,
  );
  //updateDbServicesMetadata

  useEffect(() => {
    if (responseByServiceId?.id) {
      setService(responseByServiceId);
    }
  }, [responseByServiceId]);

  return (
    <div className="page-content vh-100">
      <LoadingOverlay isLoading={!service?.id}>
        <Box display="flex" flexDirection="column" px={4} pb={2} pt={1}>
          <BackButton />
          <ServiceHeader
            service={service}
            serviceIsLoading={serviceIsLoading}
            fetchDBServiceId={fetchDBServiceId}
          />
          <ServiceSummary service={service} />
          <ServiceTabView
            service={service}
            fetchDBServiceId={fetchDBServiceId}
          />
        </Box>
      </LoadingOverlay>
    </div>
  );
}
