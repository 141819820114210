import { useEffect } from 'react';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import { useLazyGetCallWithURL } from 'common/api/useApiCall';
import { URLS } from 'constants/URL';
import { adaptConnectionPoolsResponse } from './adaptConnectionPoolsResponse';
import { useParams } from 'react-router-dom';

type ConnectionPoolsProps = {
  service?: DatabaseServiceType;
};

export const useConnectionPools = ({ service }: ConnectionPoolsProps) => {
  const params = useParams();
  const { tenantId, tenantDomain } = params;
  const {
    response: data,
    isLoading,
    postData: fetchConnectionPools,
  } = useLazyGetCallWithURL(adaptConnectionPoolsResponse);

  useEffect(() => {
    if (service?.id) {
      fetchConnectionPools(`${URLS.fetchConnectionPools}tenant-id=${tenantId}&tenant-domain=${tenantDomain}&dbservice-id=${service?.id}
      `);
    }
  }, [service]);

  return { data, isLoading };
};
