// Filters
export const CLOUD_LIST = [
  {
    id: 'ALL',
    name: 'All',
  },
  {
    id: 'aws',
    name: 'AWS',
  },
  {
    id: 'azure',
    name: 'AZURE',
  },
];

export const STATUS_LIST = [
  {
    id: 'ALL',
    name: 'All',
  },
  {
    id: 'STOPPED',
    name: 'Stopped',
  },
  {
    id: 'READY',
    name: 'Ready',
  },
  {
    id: 'STARTING',
    name: 'Starting',
  },
  {
    id: 'CREATING',
    name: 'Creating',
  },
  {
    id: 'DELETED',
    name: 'Deleted',
  },
  {
    id: 'DELETION_FAILED',
    name: 'Delete Failed',
  },
];
export const TENAT_PHASE_LIST = [
  {
    id: 'ALL',
    name: 'All',
  },
  {
    id: 'LIVE',
    name: 'Live',
  },
  {
    id: 'POC',
    name: 'POC',
  },
]

export const ENGINE_LIST = [
  {
    id: 'ALL',
    name: 'All',
  },
  {
    id: 'ORACLE',
    name: 'Oracle',
  },
  {
    id: 'POSTGRESQL',
    name: 'PostgreSQL',
  },
  {
    id: 'MYSQL',
    name: 'MySQL',
  },
  {
    id: 'SQLSERVER',
    name: 'SQL Server',
  },
  { id: 'MONGODB', name: 'MongoDB' },
  { id: 'MILVUS', name: 'Milvus' },
];
