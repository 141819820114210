import { styles } from 'assets/scss/style-templates';

export const activityErrorDrawerStyles = {
  container: {
    width: '500px',
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px 24px',
    justifyContent: 'space-between',
    columnGap: '100px',
  },
  closeButton: {
    color: styles.color.monotoneDarkDark,
  },
  errorDetailsText: {
    fontSize: '18px',
    fontWeight: 600,
  },
  errorDetailsContainer: {
    m: 3,
    p: 3,
    gap: 2,
    border: `1px solid ${styles.color.monotoneLightLight}`,
    borderRadius: '10px',
  },
  userViewContainer: {
    px: 3,
  },
  userViewText: {
    fontWeight: 700,
    fontSize: '16px',
  },
  individualErrorContainer: {
    width: '100%',
    spacing: 2,
    alignItems: 'flex-start',
  },
  title: {
    fontWeight: 700,
    fontSize: '16px',
  },
  info: {
    fontWeight: 400,
    fontSize: '14px',
  },
  failedActivityContainer: { pt: 3, width: '100%', alignItems: 'center' },
};
