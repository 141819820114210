import {
  BillingStatusFilter,
  CloudsFilter,
  DeploymentOptionsFilter,
  GeolocationFilter,
  TenantPhaseFilter,
} from 'customers/tenants-list-view/constants/tenantFilterConstants';
import { useEffect, useMemo, useState, useContext } from 'react';
import Cache from 'common/cache/lib/utils/Cache';
import { useNavigate } from 'react-router-dom';
import {
  useGetCall,
  useLazyGetCallWithURL,
  useLazyPostCall,
} from 'common/api/useApiCall';
import { tenantRegionsAdapter, tenantsAdapter } from '../tenantsAdapter';
import { ITEMS_PER_PAGE } from 'constants/appConstants';
import { TENANTS_BASE_URL, URLS } from 'constants/URL';
import { createParams } from 'helpers/createParams';
import { FilterOptions, View } from './constants';
import _debounce from 'lodash/debounce';
import { NotificationContext } from 'common/NotificationContainer/NotificationContainer';
import { exportResponseAdapter } from '../ExportData/exportResponseAdapter';
import { useFilters } from 'common/Hooks/useFilters';

export const useTenantView = () => {
  const navigate = useNavigate();
  const { addNotification } = useContext(NotificationContext);
  const { getFilters, updateFilters } = useFilters();
  const urlFilters = getFilters();
  const [page, setPage] = useState(1);
  const [view, setView] = useState(Cache.get('tenantsView') || View.Card);
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState(
    Object.keys(urlFilters).length
      ? urlFilters
      : {
          [FilterOptions.BillingStatus]: BillingStatusFilter[0].value,
          [FilterOptions.Geolocation]: GeolocationFilter[0].value,
          [FilterOptions.DeploymentType]: DeploymentOptionsFilter[0].value,
          [FilterOptions.Cloud]: CloudsFilter[0]?.value,
          [FilterOptions.TenantPhase]: TenantPhaseFilter[2].value,
          'search-query': '',
        },
  );

  const {
    response: tenantsRes,
    postData: tenantsPostData,
    isLoading: tenantsIsLoading,
  } = useLazyGetCallWithURL(tenantsAdapter);

  const { response: tenantsCountResponse, postData: tenantsCountPostData } =
    useLazyGetCallWithURL();

  const { response: tenantsRegions } = useGetCall(
    URLS.getTenantRegions,
    tenantRegionsAdapter,
  );
  const {
    response: exportResponse,
    responseStatus: exportResponseStatus,
    isLoading: exportIsLoading,
    postData: exportData,
  } = useLazyPostCall(
    `${URLS.exportTenants}${createParams(filters)}`,
    exportResponseAdapter,
  );

  const tenantsCount = useMemo(() => {
    return tenantsCountResponse?.response?.count || 0;
  }, [tenantsCountResponse]);

  const tenantsPageCount = useMemo(
    () => Math.ceil(tenantsCount / ITEMS_PER_PAGE) || 0,
    [tenantsCount],
  );

  useEffect(() => {
    if (exportResponse?.message) {
      addNotification({
        severity: exportResponseStatus === 200 ? 'success' : 'error',
        message: `${exportResponse?.message}`,
      });
    }
  }, [exportResponse]);

  useEffect(() => {
    tenantsPostData(
      `${TENANTS_BASE_URL}?page-size=${ITEMS_PER_PAGE}&page-offset=${
        page - 1
      }${createParams(filters)}`,
    );
    tenantsCountPostData(`${URLS.fetchTenantsCount}?${createParams(filters)}`);
  }, [filters, page]);

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: any,
  ) => {
    if (newView !== null) {
      Cache.set('tenantsView', newView);
      setView(Cache.get('tenantsView') || newView);
    }
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const handleFilterChange = (key: string, value: any) => {
    setPage(1);
    setFilters((prev) => ({ ...prev, [key]: value }));
    updateFilters({ ...filters, [key]: value });
  };

  const handleClickOnTenant = (id: string) => {
    const url = `/tenants/details/${id}/subscriptions`;
    navigate(url);
  };

  const debounceFn = useMemo(() => _debounce(handleFilterChange, 500), []);

  const onSearchChange = (_val) => {
    setSearchQuery(_val);
    debounceFn('search-query', _val);
  };

  const handleExportClick = (fileType: string) => {
    const payload = {
      'file-type': fileType,
    };
    exportData(JSON.stringify(payload, null));
  };

  return {
    view,
    page,
    filters,
    searchQuery,
    tenantsRes,
    tenantsCount,
    tenantsPageCount,
    tenantsIsLoading,
    exportIsLoading,
    tenantsRegions,
    onSearchChange,
    handleViewChange,
    handleClickOnTenant,
    handleFilterChange,
    handlePaginationChange,
    handleExportClick,
  };
};
