/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import { useMemo } from 'react';
import 'chartjs-adapter-moment';
import { Stack, Button, Popover, Tooltip, tooltipClasses } from '@mui/material';
import DataGrid from '../../../common/mui-data-grid/lib/components/DataGrid';
import OverflowTip from '../../../common/custom-components/lib/components/OverflowTooltip';
import { ITEMS_PER_PAGE } from 'constants/appConstants';
import { DatabaseServiceType } from 'customers/tenants-common-view/lib/schema/DatabaseService.types';
import { GetNetworkDetails } from './GetNetworkDetails';
import { GetComputeDetails } from './GetComputeDetails';
import UpdateMetadata from 'common/UpdateMetadata/UpdateMetadata';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import { CustomDataGridToolbar } from 'common/mui-data-grid/lib/components/CustomDataGridToolbar';
import { showCloudDetails, useInstances } from './useinstances';
import { InstancesColumns } from './constants';
import InstancesColumnComponents from './ColumnComponents';
import StatusLabel from 'common/custom-components/lib/components/StatusLabel';
import { isEmpty } from 'lodash';
import Info from '@mui/icons-material/Info';
import { styles } from 'assets/scss/style-templates';
import { ShowLabelValueElipsisWithCopy } from './ShowLabelValueElipsisCopy';

interface DbInstancesProps {
  service: DatabaseServiceType;
}

const cloudDetailsTooltipStyles = {
  sx: {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: styles.color.baseBackground,
      color: 'black',
      boxShadow: '0px 2px 4px rgba(8, 8, 13, 0.1)',
      fontSize: 11,
      border: `1px solid ${styles.color.greyBackground2}`,
    },
    '& .MuiTooltip-arrow': {
      '&:before': {
        border: `1px solid ${styles.color.greyBackground2}`,
      },
      color: 'white',
    },
  },
};

export default function DbInstances(props: DbInstancesProps) {
  const { service } = props;
  const {
    // page,
    // handlePaginationChange,
    instances,
    // instancesIsLoading,
    // instancesCountIsLoading,
    // instancesCount,
    // instancesPageCount,
    detailsAnchorEl,
    setDetailsAnchorEl,
    computeAnchor,
    setComputeAnchor,
    networkDetails,
    setNetworkDetails,
    computeDetails,
    setComputeDetails,
    selectedInstance,
    setSelectedInstance,
    selectedCloudResource,
    setSelectedCloudResource,
    updateType,
    setUpdateType,
    updateMetadataOpen,
    setUpdateMetadataOpen,
    cloudInstanceDetails,
    databaseServiceId,
    tenantId,
    tenantDomain,
  } = useInstances({ service });

  const dbInstanceColumns = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      field: InstancesColumns.Name,
      headerName: 'Name',
      renderCell: (params) => {
        const { row } = params;
        return InstancesColumnComponents(InstancesColumns.Name, row);
      },
      width: 100,
    },
    {
      field: InstancesColumns.Status,
      headerName: 'Status',
      renderCell: (params) => {
        const { row } = params;
        return InstancesColumnComponents(InstancesColumns.Status, row);
      },
      width: 80,
    },
    {
      field: InstancesColumns.Role,
      headerName: 'Role',
      width: 70,
      renderCell: (params) => {
        const { row } = params;
        return InstancesColumnComponents(InstancesColumns.Role, row);
      },
    },

    {
      field: InstancesColumns.AvailabilityZone,
      headerName: 'Region/AZ',
      width: 80,
      renderCell: (params) => {
        const { row } = params;
        return InstancesColumnComponents(
          InstancesColumns.AvailabilityZone,
          row,
        );
      },
    },
    {
      field: InstancesColumns.Compute,
      headerName: 'Compute',
      width: 80,
      renderCell: (params) => {
        const { row } = params;
        return InstancesColumnComponents(InstancesColumns.Compute, row);
      },
    },
    showCloudDetails && {
      field: 'cloudDetails',
      headerName: 'Cloud Details',
      width: 120,
      renderCell: (params) => {
        const { row } = params;
        const instanceDetailsObject = cloudInstanceDetails?.find(
          (_instance) => {
            return _instance?.computeResourceId === row?.computeResourceId;
          },
        );

        return isEmpty(instanceDetailsObject) ? (
          <>-</>
        ) : (
          <Stack direction={'row'} spacing={1} width="450px">
            <StatusLabel text={instanceDetailsObject?.state} />
            <Tooltip
              arrow
              title={
                <Stack py={1}>
                  <ShowLabelValueElipsisWithCopy
                    label="Id"
                    value={instanceDetailsObject?.id}
                  />
                  <ShowLabelValueElipsisWithCopy
                    label="Name"
                    value={instanceDetailsObject?.name}
                  />
                </Stack>
              }
              PopperProps={cloudDetailsTooltipStyles}
              componentsProps={{
                tooltip: {
                  sx: {
                    minWidth: '350px',
                  },
                },
              }}
            >
              <Info color="primary" sx={{ fontSize: '16px' }} />
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: InstancesColumns.Endpoint,
      headerName: 'Connection Endpoint',
      width: 250,
      renderCell: (params) => {
        const { row } = params;
        const { connectionInfo } = row;
        const { connectString = {} } = connectionInfo || {};
        const endpoint = connectString?.endpoint || '';

        return (
          <Stack direction="column" justifyContent="center" spacing={0.5}>
            {endpoint ? (
              <Stack
                direction="column"
                justifyContent="flex-start"
                spacing={0.5}
              >
                <OverflowTip text={endpoint} placement="left" variant="body2" />
                <Button
                  color="primary"
                  component="button"
                  sx={{ alignSelf: 'flex-start', padding: '0px' }}
                  onClick={(e) => {
                    setDetailsAnchorEl(e.currentTarget);
                    setNetworkDetails(connectString);
                  }}
                >
                  See Details
                </Button>
              </Stack>
            ) : (
              '-'
            )}
          </Stack>
        );
      },
    },
    {
      field: 'computeInfo',
      headerName: 'Compute Resource',
      width: 100,
      renderCell: (params) => {
        const { row } = params;
        return (
          <Stack direction="column" justifyContent="center" spacing={0.5}>
            <Stack direction="column" justifyContent="flex-start" spacing={0.5}>
              <Button
                color="primary"
                component="button"
                sx={{ alignSelf: 'flex-start', padding: '0px' }}
                onClick={(e) => {
                  setComputeAnchor(e.currentTarget);
                  setComputeDetails(row);
                }}
              >
                See Details
              </Button>
            </Stack>
          </Stack>
        );
      },
    },
  ].filter((col) => col);

  const menuItemsProps = useMemo(
    () => [
      {
        label: 'Update Instance Metadata',
        onClick: (row) => {
          setUpdateType('instance');
          setSelectedInstance(row);
          setUpdateMetadataOpen(true);
        },
      },
      {
        label: 'Update Compute Resource',
        onClick: (row) => {
          setUpdateType('computeResource');
          setSelectedCloudResource(row?.computeResourceId);
          setSelectedInstance(row);
          setUpdateMetadataOpen(true);
        },
      },
    ],
    [],
  );

  return (
    <Stack sx={{ height: '60vh' }}>
      <DataGrid
        columns={dbInstanceColumns}
        menuItems={menuItemsProps}
        rows={instances}
        styleOverrides={{ hideShadow: true }}
        rowHoverId="id"
        getRowId={(row) => `${row.id}`}
        // loading={instancesIsLoading || instancesCountIsLoading}
        components={{
          Toolbar: CustomDataGridToolbar,
          DetailPanelCollapseIcon: ExpandMore,
          DetailPanelExpandIcon: ChevronRight,
        }}
        componentsProps={{
          toolbar: { showQuickFilter: true },
        }}
        itemsPerPage={ITEMS_PER_PAGE}
        // pageNo={page}
        totalCount={instances?.length || 0}
        // handlePaginationChange={handlePaginationChange}
        // pageCount={instancesPageCount}
      />
      <Popover
        open={Boolean(detailsAnchorEl)}
        anchorEl={detailsAnchorEl}
        onClose={() => setDetailsAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {networkDetails && GetNetworkDetails(networkDetails)}
      </Popover>

      <Popover
        open={Boolean(computeAnchor)}
        anchorEl={computeAnchor}
        onClose={() => setComputeAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {computeDetails && (
          <GetComputeDetails computeId={computeDetails?.computeResourceId} />
        )}
      </Popover>
      <UpdateMetadata
        open={updateMetadataOpen}
        setOpen={setUpdateMetadataOpen}
        entityType={updateType}
        entityName={selectedInstance?.name}
        tenantId={tenantId}
        tenantDomain={tenantDomain}
        entityId={
          updateType === 'instance'
            ? selectedInstance?.id
            : selectedCloudResource
        }
        parentEntityId={databaseServiceId}
      />
    </Stack>
  );
}
